import { defineStore } from "pinia";
import {
    ICustomComponent,
    IPage,
    IPageBuilderStore,
    PublicIntegration,
} from "../interfaces/index";
import {
    flattenArray,
    getIndexPath,
    generateUID,
    setComponentDefaults,
    setDesignDefaults,
    setNewComponentIds,
    getComponentById,
    deleteComponentById,
    getEmptyComponent,
    pushErrorToComponents,
    removeErrorFromComponents
} from "../utilities";
import { ComponentType, ContainerType, Direction, FieldStyle, PageType, SubmitAction } from "@/builder/enums";
import OrganizationDesignSettings from "../../interfaces/OrganizationDesignSettings";
import { ComponentErrors } from "../errors";
import { IntegrationType } from "../../enums";

export const useBuilderStore = defineStore({
    id: "builder",
    state(): IPageBuilderStore {
        return {
            organizationId: null,
            page: {
                id: null,
                name: "",
                slug: null,
                publicId: null,
                components: [],
                pageType: PageType.Full,
                formType: null,
                partialDisplayType: null,
                gatewayId: null,
                selectedGateway: null,
                onSuccess: null,
                onLoad: null,
                isPublished: false,
                merchantAccount: null,
                tokenizationKey: null,
                defaultSegment: null,
                defaultSegmentCode: null,
                defaultCampaignId: null,
                defaultCampaignName: null,
                segmentOverrideCode: null,
                confirmationDonation: null,
                recurringEmail: null,
                integrations: [],
                useTrackingPixel: false,
                pageDesign: {
                    websiteUrl: null,
                    logoUrl: null,
                    modalDisplay: null,
                    fieldStyle: FieldStyle.Default,
                    primaryColor: null,
                    secondaryColor: null,
                    backgroundColor: null,
                    headerColor: null,
                    headerTextColor: null,
                    bodyTextColor: null,
                    footerColor: null,
                    footerTextColor: null,
                    buttonColor: null,
                    buttonTextColor: null,
                    buttonHoverColor: null,
                    buttonHoverTextColor: null,
                    linkColor: null,
                    fieldLabelColor: null,
                    fieldBorderColor: null,
                    borderRadius: null,
                    fontFamily: null,
                    customCss: null,
                },
                pageSettings: {
                    submitAction: SubmitAction.Message,
                    fundraisingGoal: '',
                    expirationDate: '',
                    motivationCode: '',
                    segmentCode: '',
                    mediaOutlet: '',
                    thankYouMessage: null,
                    showDifferentMessageForRecurring: false,
                    confirmationEmailSubject: '',
                    confirmationEmailContent: '',
                    customSocialSharingMessage: false,
                    showDonorPortalLink: false,
                    showHelpAndInfoLink: false,
                    customJs: null,
                }
            },
            builder: {
                step: 0,
                maxStep: 2,
                selectedId: null,
                expandForms: true,
                domain: null,
                selectedType: null,
                activeContainerId: null,
                activeContainerType: ContainerType.Body,
                integrations: [],
            },
            isEditable: false,
            isLoading: false,

            modelState: {},
            isValid: true,

            originalState: null
        };
    },
    getters: {
        /**
         * @name: Get Component
         * @desc: Finds a component by the specified identifier.
         **/
        getComponent: (state: any) => {
            return (id: string) => {
                return getComponentById(state.page.components, id);
            };
        },

        hasComponentByType: (state: any) => {
            return (type: ComponentType) => {
                return flattenArray(state.page.components).some(
                    (x) => x.type === type
                );
            };
        },

        selectedEditor(): string | null {
            if (!this.builder.selectedType) return null;

            return this.builder.selectedType.replace("-component", "-editor");
        },

        selectedTitle(): string {
            if (!this.builder.selectedType) return "Edit Component";

            return (
                this.builder.selectedType
                    ?.replace("-component", "")
                    ?.split("-")
                    ?.join(" ") ?? "Edit Component"
            );
        },

        showAddModal(): boolean {
            return this.builder.activeContainerId !== null;
        },

        pageTypeDisplay(): string {
            switch (this.page.pageType) {
                case PageType.Full: {
                    return "page";
                }
                case PageType.Partial: {
                    return "form";
                }
                case PageType.Component: {
                    return "element";
                }
                default:
                    return "page";
            }
        },

        pageUrl(): string | null {
            if (this.page.pageType !== PageType.Full) return null;

            return `${this.builder.domain}/donate/${this.page.slug}`;
        },

        oneTimeComponentTypes(): ComponentType[] {
            return flattenArray(this.page.components)
                .filter((x) => !x.controls.allowMultiple)
                .map((x) => x.type);
        },

        oneTimeDuplicates(): ICustomComponent[] {
            //get array of components that allow multiple is false and there is more than one
            const oneTimeComponents = flattenArray(this.page.components)
                .filter((x) => !x.controls.allowMultiple)
                .filter((x) => {
                    const components = flattenArray(this.page.components).filter(
                        (y) => y.type === x.type
                    );

                    return components.length > 1;
                });

            return oneTimeComponents;
        },

        componentErrors(): string[] {
            const errors: string[] = [];

            //return list of errors for each component
            flattenArray(this.page.components).forEach((component) => {
                if (!component.errors) return;

                component.errors.forEach((error) => {
                    errors.push(error);
                });
            });

            return errors;
        },

        hasIntegration: (state: IPageBuilderStore) => {
            return (integrationType: IntegrationType) => {

                if (!state.builder.integrations) return false;

                return state.builder.integrations.some((x: PublicIntegration) => x.integrationType === integrationType);
            };
        },

        getIntegration: (state: IPageBuilderStore) => {
            return (integrationType: IntegrationType) => {

                if (!state.builder.integrations) return null;

                return state.builder.integrations.find((x: PublicIntegration) => x.integrationType === integrationType);
            };
        },

        //canUndo: (state) => {
        //    return state.historyIndex > 0;
        //},

        //canRedo: (state) => {
        //    return state.historyIndex < state.historyCount;
        //}
    },
    actions: {
        /*
         * Steps
         **/
        nextStep(): void {
            if (this.builder.step >= this.builder.maxStep) return;

            this.builder.step += 1;
        },

        prevStep(): void {
            if (this.builder.step <= 0) return;

            this.builder.step--;
        },

        moveToStep(step: number): void {
            this.builder.step = step;
        },

        /**
         * Components
         **/

        openComponentModal(
            containerId: string,
            containerType: ContainerType
        ): void {
            this.builder.activeContainerId = containerId;
            this.builder.activeContainerType = containerType;
        },

        closeComponentModal(): void {
            this.builder.activeContainerType = null;
            this.builder.activeContainerId = null;
        },

        addSection(id: string, containerType: ContainerType) {
            this.builder.activeContainerId = id;
            this.builder.activeContainerType = containerType;

            const emptySection = getEmptyComponent(ComponentType.Section);
            this.addComponent(emptySection, containerType);

            this.setSelectedId(emptySection.id);
        },

        addFormStep(id: string) {
            //get the form component
            const form = this.getComponent(id);
            if (!form) return;

            const emptyFormStep = getEmptyComponent(ComponentType.FormStep);

            //add new form step to the form as first child component
            form.components.unshift(emptyFormStep);

            this.setSelectedId(emptyFormStep.id);
        },

        addComponent(component: ICustomComponent, containerType: ContainerType | null): void {

            if (!this.builder.activeContainerId) return;
            if (!containerType) return;

            component.id = generateUID();
            component.parentId = this.builder.activeContainerId;

            switch (containerType) {
                case ContainerType.Body: {
                    const currentIndex = this.page.components
                        .map((x) => x.id)
                        .indexOf(this.builder.activeContainerId);
                    component.id = generateUID();
                    if (component.type === ComponentType.Footer) {
                        this.page.components.push(component);
                        break;
                    }
                    this.page.components.splice(currentIndex, 0, component);
                    break;
                }
                case ContainerType.Form:
                case ContainerType.Form_Step:
                case ContainerType.Column:
                case ContainerType.Confirmation: {
                    const container = this.getComponent(this.builder.activeContainerId);
                    container.components.unshift(component);
                    break;
                }
            }

            pushErrorToComponents(this.oneTimeDuplicates, ComponentErrors.DuplicateComponent);

            this.setSelectedId(component.id);

            this.closeComponentModal();
        },

        moveComponent(id: string, direction: Direction): void {
            const path = getIndexPath(this.page.components, id);
            const siblingComponents =
                path.length > 1
                    ? this.getComponent(path[path.length - 2].id)?.components
                    : this.page.components;

            const currentIndex = path[path.length - 1].index;
            let toIndex: number;

            //get header index
            const headerIndex = siblingComponents.findIndex(
                (x) => x.type === ComponentType.Header
            );

            //get footer index
            const footerIndex = siblingComponents.findIndex(
                (x) => x.type === ComponentType.Footer
            );

            switch (direction) {
                case Direction.Up:
                    if (currentIndex === 0) return;

                    toIndex = currentIndex - 1;

                    //if the toIndex equals the header index, return, we don't want to move above the header
                    if (toIndex === headerIndex) return;

                    break;
                case Direction.Down:
                    if (currentIndex === siblingComponents.length - 1) return;

                    toIndex = currentIndex + 1;

                    //if the toIndex equals the footer index, return, we don't want to move below the footer
                    if (toIndex === footerIndex) return;

                    break;
            }

            const item = siblingComponents[currentIndex];
            siblingComponents.splice(currentIndex, 1);
            siblingComponents.splice(toIndex, 0, item);
        },

        setSelectedId(id: string): void {
            const component = this.getComponent(id);
            this.builder.selectedType = component.type;
            this.builder.selectedId = id;
        },

        openEditor(id: string, containerType: ContainerType): void {
            this.setSelectedId(id);
            this.builder.activeContainerType = containerType;
        },

        deleteComponent(id: string): void {
            this.builder.selectedType = null;
            this.builder.selectedId = null;

            //clear errors from one time components
            removeErrorFromComponents(this.oneTimeDuplicates, ComponentErrors.DuplicateComponent);

            const parentId = this.getComponent(id).parentId;
            const siblingId = deleteComponentById(this.page.components, id);

            //add errors for one time components duplicates
            pushErrorToComponents(this.oneTimeDuplicates, ComponentErrors.DuplicateComponent);

            if (siblingId) {
                this.setSelectedId(siblingId);
            } else if (parentId) {
                this.setSelectedId(parentId);
            }
        },

        duplicateComponent(id: string): void {
            const component = this.getComponent(id);

            //duplicate the component
            const duplicate = JSON.parse(JSON.stringify(component));

            //set new id for the duplicate and its children
            duplicate.id = generateUID();
            setNewComponentIds(duplicate.components);

            //add the duplicate to the page
            const path = getIndexPath(this.page.components, id);
            const siblingComponents =
                path.length > 1
                    ? this.getComponent(path[path.length - 2].id)?.components
                    : this.page.components;

            const currentIndex = path[path.length - 1].index;
            siblingComponents.splice(currentIndex + 1, 0, duplicate);

            pushErrorToComponents(this.oneTimeDuplicates, ComponentErrors.DuplicateComponent);

            //select the duplicate
            this.setSelectedId(duplicate.id);
        },

        setModelState(modelState: any): void {
            this.isValid = Object.keys(modelState).length === 0;
            this.modelState = modelState;
        },

        expandForms() {
            this.builder.expandForms = !this.builder.expandForms;
        },

        /**
         * @name: Export State
         * @desc: take the state of the builder, serializes it to a JSON string
         *      and then compresses using btoa (base-64) compression.
         **/
        get() {
            return btoa(unescape(encodeURIComponent(JSON.stringify(this.$state.page).replace(/[\u00A0-\u2666]/g, function (c) {
                return '&#' + c.charCodeAt(0) + ';';
            }))));
        },

        /**
         * @name: Import State
         * @desc: takes the temp state and applies it to reload from a specific point.
         **/
        load(page: any) {
            this.page = {
                ...this.page,
                ...JSON.parse(atob(page.json))
            };

            this.builder.step = 0;
            this.builder.integrations = page.integrations;

            this.page.id = page.id;
            this.page.name = page.name;
            this.page.slug = page.slug;
            this.page.isPublished = page.isPublished;
            this.page.publicId = page.publicId;
            this.page.gatewayId = page.gatewayId;
            this.page.merchantAccount = page.merchantAccount;
            this.page.tokenizationKey = page.tokenizationKey;
            this.page.defaultSegmentCode = page.defaultSegmentCode;

            setDesignDefaults(this.page, page.designSettings as OrganizationDesignSettings);
            setComponentDefaults(this.page.components, this.page);

            if (page.confirmationDonationId) {
                this.page.confirmationDonation = { id: page.confirmationDonationId, name: page.confirmationDonationName }
            }

            if (page.recurringEmailId) {
                this.page.recurringEmail = { id: page.recurringEmailId, name: page.recurringEmailName }
            }

            this.originalState = this.get();
        },

        reset() {
            this.$reset();
        },

        handleSuccessfulSave(response: IPage) {
            this.page.id = response.id;
            this.page.isPublished = response.isPublished;
            this.page.publicId = response.publicId;
        },

        //------------------ Proof of Concept: History w/undo and redo ------------------//
        //const STORED_HISTORY_KEY = 'builder-history';
        //const STORED_HISTORY_INDEX = 'builder-history-index';
        //clearHistory() {
        //    localStorage.setItem(STORED_HISTORY_KEY, '');
        //    localStorage.setItem(STORED_HISTORY_INDEX, '');
        //},

        //trackHistory(newState: CustomComponent[]) {
        //    let parsedHistory:any = [];

        //    const storedHistory = localStorage.getItem(STORED_HISTORY_KEY);
        //    if (storedHistory) {
        //        parsedHistory = JSON.parse(atob(storedHistory));
        //    }

        //    parsedHistory.push(newState);

        //    localStorage.setItem(STORED_HISTORY_KEY, btoa(JSON.stringify(parsedHistory)));

        //    //reset starting index when mutation occurs
        //    this.historyIndex = parsedHistory.length - 1;
        //    this.historyCount = parsedHistory.length;
        //},

        //undo() {
        //    let parsedHistory: any = [];

        //    const storedHistory = localStorage.getItem(STORED_HISTORY_KEY);
        //    if (!storedHistory) return;

        //    parsedHistory = JSON.parse(atob(storedHistory));

        //    this.historyIndex = this.historyIndex - 1;
        //    this.components = parsedHistory[this.historyIndex];
        //},

        //redo() {
        //    let parsedHistory: any = [];

        //    const storedHistory = localStorage.getItem(STORED_HISTORY_KEY);
        //    if (!storedHistory) return;

        //    parsedHistory = JSON.parse(atob(storedHistory));

        //    this.historyIndex = this.historyIndex + 1;
        //    this.components = parsedHistory[this.historyIndex];
        //}
    },

    share: {
        enable: false,
    },
});

//import { MutationType } from 'pinia';
//const builderStore = useBuilderStore();
//builderStore.$subscribe((mutation, state) => {
//    if (mutation.type !== MutationType.direct) return;
//    if (mutation.events.key === 'historyIndex') return;
//    if (mutation.events.key === 'historyCount') return;
//    if (mutation.events.key === 'components') return;

//    //if (!mutation.events.newValue) return;
//    //if (!mutation.events.newValue.id) return;
//    //if (!mutation.events.newValue.id.startsWith('component_')) return;

//    console.log(mutation);

//    builderStore.trackHistory(state.components);
//})

//const builderStore = useBuilderStore();
//builderStore.$subscribe((mutation, state) => {
//    console.log(mutation);
//})
