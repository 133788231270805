import { EmailDesign } from "@/stores/emailBuilderStore";
import { DefaultButton } from "./DefaultButton";
import { DefaultDivider } from "./DefaultDivider";
import { DefaultLogo } from "./DefaultLogo";
import { DefaultTemplate } from "./DefaultTemplate";
import { DefaultText } from "./DefaultText";

export const DefaultLeadConfirmationEmail = (design: EmailDesign) => {
    return DefaultTemplate(design, [
        DefaultLogo(design),
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><strong>Thanks for your interest!</strong><br>We appreciate your interest in {{OrganizationName}}. You can expect more information from our team soon.</p></div>'
        ),
        DefaultDivider,
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><strong>Learn More About Us</strong>&nbsp;</p><p style="text-align: center; line-height: 14px; word-break: break-word;" data-renderer-start-pos="8657" data-mce-style="text-align: center; line-height: 14px;">In the meantime, visit our website to learn more about our work.</p></div>'
        ),
        DefaultButton(design, "Learn More", "{{OrganizationUrl}}"),
    ]);
};
