import { EmailDesign } from "@/stores/emailBuilderStore";
import { DefaultButton } from "./DefaultButton";
import { DefaultDivider } from "./DefaultDivider";
import { DefaultLogo } from "./DefaultLogo";
import { DefaultTemplate } from "./DefaultTemplate";
import { DefaultText } from "./DefaultText";

export const DefaultDonationConfirmationEmail = (design: EmailDesign) => {
    return DefaultTemplate(design, [
        DefaultLogo(design),
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><strong>Thanks for your generous gift!</strong><br>Support from donors like you allow {{OrganizationName}} to continue making a positive impact on the world.</p></div>'
        ),
        DefaultDivider,
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px;"><strong>Organization Name</strong><br>{{OrganizationName}}<br><br><strong>Gift Amount&nbsp;</strong><br>{{GiftAmount}}<br><br><strong>Payment Method</strong><br>{{GiftPaymentLast4}}<br><br><strong>Gift Date</strong><br>{{GiftDate}}<br><br>Project Allocation(s)<br><strong>{{Projects}}</strong></p></div>'
        ),
        DefaultDivider,
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><strong>Manage Your Donation</strong><br>Easily update your contact information, make changes to your recurring gift, and have receipts resent to you by email.</p></div>'
        ),
        DefaultButton(design, "Donor Portal Login", "{{DonorPortalLogin}}"),
    ]);
};
