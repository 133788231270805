import { EmailDesign } from "@/stores/emailBuilderStore";

export const DefaultLogo = (design: EmailDesign) => {
    return {
        descriptor: {
            computedStyle: {
                hideContentOnAmp: false,
                hideContentOnDesktop: false,
                hideContentOnHtml: false,
                hideContentOnMobile: false,
            },
            html: {
                html: `<img src="${design.logoUrl}" alt="Organization Logo" />`,
            },
            style: {
                "padding-bottom": "0px",
                "padding-left": "0px",
                "padding-right": "0px",
                "padding-top": "0px",
            },
        },
        locked: false,
        type: "mailup-bee-newsletter-modules-html",
    };
};
