import axios, { AxiosInstance } from "axios";
import CostRequest from "../interfaces/requests/CostRequest";
import { DonateRequest, PremiumResponseModel, VisitorGivingResponse, VisitorRequest, PageResponse, CampaignResponseModel, DonateResponse } from "../builder/interfaces";
import { getCookie } from "../builder/utilities";
import { CostResponse, LookupResponse } from "../interfaces";

export class publicApiService {
    baseUrl: string;
    publicAxios: AxiosInstance;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;

        this.publicAxios = axios.create({
            withCredentials: false,
        });
    }

    submitDonation = async (request: DonateRequest, organizationId: number | null) =>
        this.post<DonateResponse>(`${this.baseUrl}/donate/` + organizationId, request);

    getPage = (pageId: string, organizationId: number | null) =>
        this.get<PageResponse>(`${this.baseUrl}/page/${organizationId}/${pageId}`);

    getProject = (projectId: string, organizationId: number | null) =>
        this.get<any>(`${this.baseUrl}/project/${organizationId}/${projectId}`);

    getPremium = (premiumId: string, organizationId: number | null) =>
        this.get<PremiumResponseModel>(`${this.baseUrl}/premium/${organizationId}/${premiumId}`);

    getCampaign = (campaignId: string, organizationId: number | null) =>
        this.get<CampaignResponseModel>(`${this.baseUrl}/campaign/${organizationId}/${campaignId}`);

    getCountries = () =>
        this.get<any>(`${this.baseUrl}/lookup/countries`);

    getStates = (countryCode: string | null) =>
        this.get<any>(`${this.baseUrl}/lookup/states/${countryCode}`);

    searchAddress = (searchTerm: string | null) =>
        this.get<any>(`${this.baseUrl}/lookup/address/${searchTerm}`);

    calculateCosts = (costRequest: CostRequest, organizationId: number | null) =>
        this.get<CostResponse>(`${this.baseUrl}/donate/cost/` + organizationId, costRequest);

    findVisitor = (organizationId: number | null) => {
        const visitorId = getCookie('vcrmvid') ?? "";
        const individualId = getCookie('vcrmiid') ?? "";

        const request: VisitorRequest = {
            visitorId: visitorId,
            individualId: individualId
        };

        return this.get<VisitorGivingResponse>(`${this.baseUrl}/donate/visitor/${organizationId}`, request);
    }

    getFonts = () => {
        return this.get<LookupResponse[]>(`${this.baseUrl}/lookup/fonts`);
    }

    private async get<T>(url: string, params?: any): Promise<T> {
        try {
            const response = await this.publicAxios.get<T>(url, { params });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    private async post<T>(url: string, data?: any): Promise<T> {
        try {
            const response = await this.publicAxios.post<T>(url, data);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
