import { nextTick } from "vue";
import { defineStore } from "pinia";
import { amountToNumber, flattenArray, formatCurrency, getCookie, getFormStep } from "@/builder/utilities";
import { AddressType, Frequency, IntegrationType } from "@/enums";
import {
    DonateDonorRequest,
    DonateRequest,
    DonateResponse,
    ICustomComponent,
    IPage,
    ProjectSplit,
    PremiumResponseModel,
    PublicIntegration,
    VisitorGivingResponse,
    PageResponse,
    Gift,
    StepError
} from "../interfaces";
import AddressRequest from "../../interfaces/requests/AddressRequest";
import { Toast, ToastType } from "../../components/shared/toast/interfaces";
import { ButtonActionType } from "../components/button/ButtonInterface";
import { Tribute } from "../../interfaces";
import { TrackingEventType, trackingPixelService } from "@/services/trackingPixelService";
import { publicApiService } from "@/services/publicApiService";

export interface RenderStore {
    step: number;
    maxSteps: number;
    showModal: boolean;
    builderMode: boolean;
    previewMode: boolean;
    environment: string;
    pageResponse: PageResponse;
    page: IPage;
    donor: DonateDonorRequest;
    hasTribute: boolean;
    tribute: Tribute;
    gift: Gift;
    doubleTheDonationCompanyId: string | null;
    doubleTheDonationEnteredText: string | null;
    validating: boolean;
    validateInputStep: number | null;
    modelState: any;
    errors: StepError[];
    toasts: Toast[];
    organizationId: number | null;
    visitorId: string | null;
    success: boolean;
    transactionId: string | null;

    isLoading: boolean;
    isProcessing: boolean;
    formStarted: boolean;

    visitorGiving: VisitorGivingResponse | null;
    submissionRequiresAction: boolean;
    paymentClientSecret: string | null;
    paymentId: string | null;
}

export const useRenderStore = defineStore("render", {
    state(): RenderStore {
        return {
            step: 0,
            maxSteps: 0,
            showModal: false,
            builderMode: false,
            previewMode: false,
            environment: "local",
            isLoading: false, //used for loading page elements or data
            isProcessing: false, //used for processing a payment
            success: false,
            transactionId: null,
            organizationId: null,
            doubleTheDonationCompanyId: null,
            doubleTheDonationEnteredText: null,
            pageResponse: {} as PageResponse,
            page: { components: [] as ICustomComponent[] } as IPage,

            donor: {
                title: null,
                firstName: "",
                middleName: null,
                lastName: "",
                suffix: null,
                email: "",
                phone: null,
                phoneOptIn: false,
                isOrganization: false,
                organizationName: null,
                crmKey: null,
                billingAddress: {
                    address1: null,
                    address2: null,
                    city: null,
                    state: null,
                    postal: null,
                    countryString: null,
                    addressType: AddressType.Billing,
                } as AddressRequest,
                shippingAddress: null,
            },
            hasTribute: false,
            tribute: {
                isInHonorOf: true,
                isInMemoryOf: false,
                NameOrOccasion: '',
                tributeFirstName: '',
                tributeLastName: '',
                tributeAddress: {
                    address1: null,
                    address2: null,
                    city: null,
                    state: null,
                    postal: null,
                    countryString: null,
                    addressType: AddressType.Billing,
                } as AddressRequest,
                acknowledgeeFirstName: '',
                acknowledgeeLastName: '',
                sendByEmail: true,
                acknowledgeeEmailAddress: '',
                sendByPostal: false,
                acknowledgeeAddress: {
                    address1: null,
                    address2: null,
                    city: null,
                    state: null,
                    postal: null,
                    countryString: null,
                    addressType: AddressType.Billing,
                } as AddressRequest,
                message: '',
            },
            gift: {
                amount: "",
                totalWithCost: "",
                showCostPreview: false,
                paymentMethodId: null,
                paymentMethodType: null,
                frequency: null,
                creditCardType: "",
                comments: "",
                startDate: null,
                coverCosts: false,
                projectSplits: [] as ProjectSplit[],
                premium: null,
            },
            visitorId: null,
            validating: false,
            validateInputStep: null,
            modelState: {},
            errors: [],
            toasts: [] as Toast[],

            formStarted: false,
            visitorGiving: null,
            submissionRequiresAction: false,
            paymentClientSecret: null,
            paymentId: null,
        };
    },
    getters: {
        apiBaseUrl: (state) => {
            switch (state.environment) {
                case "local":
                    return "https://localhost:7236/api";
                case "development":
                    return "https://dev-give-tnrd.azurewebsites.net/api";
                case "demo":
                    return "https://demo-give-tnrd.azurewebsites.net/api";
                case "production":
                default:
                    return "https://public.raisedonors.com/api";
            }
        },

        cdnUrl: (state) => {
            switch (state.environment) {
                case "local":
                    return "https://raisedonorsdev.azureedge.net";
                case "development":
                    return "https://raisedonorsdev.azureedge.net";
                case "demo":
                    return "https://raisedonorsqa.azureedge.net";
                case "production":
                default:
                    return "https://raisedonorsprod.azureedge.net";
            }
        },

        trackingPixelUrl: (state) => {
            switch (state.environment) {
                case "local":
                    return "https://cdn.virtuoussoftware.com/tracker/virtuous.tracker.shim.dev.js";
                case "development":
                    return "https://cdn.virtuoussoftware.com/tracker/virtuous.tracker.shim.dev.js";
                case "demo":
                    return "https://cdn.virtuoussoftware.com/tracker/virtuous.tracker.shim.qa.js";
                case "production":
                default:
                    return "https://cdn.virtuoussoftware.com/tracker/virtuous.tracker.shim.min.js";
            }
        },

        totalAmount: (state) => {
            let amount = amountToNumber(state.gift.amount);

            if (state.gift.projectSplits.length > 0) {
                amount = 0;

                for (let i = 0; i < state.gift.projectSplits.length; i++) {
                    const projectSplit = state.gift.projectSplits[i];
                    const projectSplitAmount = amountToNumber(projectSplit.amount);

                    if (isNaN(projectSplitAmount)) continue;

                    amount += projectSplitAmount;
                }
            }

            return amount;
        },

        isProjectActive: (state) => {
            return (projectId) =>
                state.gift.projectSplits.find(
                    (project) => project.projectId === projectId
                );
        },

        isPremiumActive: (state) => {
            return (premiumId: string) => {
                return state.gift.premium?.id.toString() === premiumId;
            }
        },

        isRecurring: (state) => {
            return (
                state.gift.frequency != null &&
                state.gift.frequency !== Frequency.OneTime
            );
        },

        getComponent: (state: any) => {
            return (id: string) => {
                const component = flattenArray(state.page.components).find(
                    (x) => x.id === id
                ) as ICustomComponent;

                return component;
            };
        },

        getFormStep: (state: any) => {
            return (componentId: string) => {
                const component = state.getComponent(componentId);
                if (!component || !component.parentId) return null;

                const formStep = getFormStep(
                    component.parentId,
                    state.page.components
                );
                return formStep;
            };
        },

        hasErrors: (state: any) => {
            return state.errors.length > 0;
        },

        hasRequiredSubmitFields: (state: any) => {
            return (
                state.gift.amount &&
                state.gift.paymentMethodId &&
                state.gift.paymentMethodType
            )
        },

        Loading: (state: RenderStore) => {
            return state.isLoading;
        },

        canNotSubmit() {
            const hasErrors = this.hasErrors;
            const Loading = this.Loading;

            return hasErrors || Loading;
        },

        stepHasErrors: (state) => {
            return (step: number) => {
                const stepErrors = state.errors.filter((x) => x.step === step);
                return stepErrors.length > 0;
            };
        },

        hasIntegration: (state: RenderStore) => {
            return (integrationType: IntegrationType) => {

                if (!state.page.integrations) return false;

                return state.page.integrations.some((x: PublicIntegration) => x.integrationType === integrationType);
            };
        },

        getIntegration: (state: RenderStore) => {
            return (integrationType: IntegrationType) => {

                if (!state.page.integrations) return null;

                return state.page.integrations.find((x: PublicIntegration) => x.integrationType === integrationType);
            };
        },
    },
    actions: {
        moveStep(actionType: ButtonActionType) {
            this.validateInputStep = this.step;

            switch (actionType) {
                case ButtonActionType.NextStep: {
                    //don't allow moving forward if there are errors
                    if (this.stepHasErrors(this.step)) return;

                    if (this.step < this.maxSteps) this.step += 1;
                    break;
                }
                case ButtonActionType.PrevStep:
                    if (this.step > 0) this.step -= 1;
                    break;
                default:
                    return console.error("Unexpected Button Action Type");
            }
        },

        moveToErrorStep() {
            const errorSteps = this.errors.map((x) => x.step as number);
            const firstStepWithError = Math.min(...errorSteps);
            this.step = firstStepWithError;
        },

        getAmount(projectId?: string | null): string {
            if (!projectId) return this.gift.amount ?? "0";

            return (
                this.gift.projectSplits?.find((x) => x.projectId === projectId)
                    ?.amount ?? ""
            );
        },

        setMaxSteps(maxSteps: number): void {
            this.maxSteps = maxSteps;
        },

        setEnvironment(environment: string): void {
            this.environment = environment;
        },

        setBuilderMode(builderMode: boolean): void {
            this.builderMode = builderMode;
        },

        setPreviewMode(previewMode: boolean): void {
            this.previewMode = previewMode;
        },

        setOrganizationId(organizationId: string): void {
            this.organizationId = parseInt(organizationId);
        },

        setFrequency(frequency: Frequency | null): void {
            this.gift.frequency = frequency;
        },

        setAmount(
            amount: string,
            projectId?: string | null,
            allowMultipleProjects?: boolean | null
        ): void {
            amount = amountToNumber(amount).toString();
            const formattedAmount = formatCurrency(amount);

            if (this.page.useTrackingPixel) {
                const trackingPixel = new trackingPixelService();

                //if form is not started, set true and track event
                if (!this.formStarted) {
                    this.formStarted = true;
                    trackingPixel.trackEvent(TrackingEventType.FormStart, {
                        formId: this.page.id?.toString() ?? "",
                        formName: this.page.name,
                    });
                }
            }

            if (!this.gift.projectSplits) this.gift.projectSplits = [];

            if (!allowMultipleProjects) {
                if (!projectId) {
                    //if this gift array is not bound to a project there is a single gift array
                    if (this.gift.projectSplits.length > 0) {
                        //and a user selected an amount after a project (project was set with 0)
                        const projectSplitAmount = (
                            amountToNumber(amount) /
                            this.gift.projectSplits.length).toFixed(2);

                        this.gift.projectSplits.forEach((projectSplit) => {
                            projectSplit.amount = projectSplitAmount;
                        });
                    }
                }
            }

            //if this gift array is not bound to a project, just set the amount
            if (!projectId) {
                this.gift.amount = formattedAmount;
                return;
            }

            //clear out any other project splits if we're not allowing multiple projects
            if (!allowMultipleProjects) {
                this.gift.projectSplits = this.gift.projectSplits.filter(
                    (x) => x.projectId == projectId
                );
            }

            //update the existing project split if it exists
            const existingProjectSplit = this.gift.projectSplits.find(
                (x) => x.projectId === projectId
            );

            if (existingProjectSplit) {
                if (!amount || (existingProjectSplit.amount === amount)) {
                    this.removeProject(projectId);
                    return;
                } else {
                    //update the amount
                    existingProjectSplit.amount = amount;
                }
            }
            else {
                //add a new project split
                const projectSplit: ProjectSplit = {
                    projectId: projectId,
                    projectName: "", //this will get set by a watcher in the project card component
                    amount: amount,
                };

                this.gift.projectSplits.push(projectSplit);
            }

            //update the gift amount
            if (this.gift.projectSplits.length > 0) {
                let projectSum = 0;

                for (let i = 0; i < this.gift.projectSplits.length; i++) {
                    const projectSplit = this.gift.projectSplits[i];
                    const projectSplitAmount = amountToNumber(projectSplit.amount);

                    if (isNaN(projectSplitAmount)) continue;

                    projectSum += projectSplitAmount;
                }

                this.gift.amount = formatCurrency(projectSum.toString());
            }
            else {
                this.gift.amount = formattedAmount;
            }
        },

        removeProject(projectId: string): void {
            const projectSplitIndex = this.gift.projectSplits.findIndex(
                (x) => x.projectId === projectId
            );
            if (projectSplitIndex > -1) {
                this.gift.projectSplits.splice(projectSplitIndex, 1);
            }

            //if there are no more project splits, set the gift amount to 0
            if (this.gift.projectSplits.length === 0) {
                this.gift.amount = formatCurrency('0');
            }
        },

        setPremium(premium: PremiumResponseModel | null): void {
            this.gift.premium = premium;
        },

        removePremium(): void {
            this.gift.premium = null;
        },

        getRequest() {
            return {
                donor: {
                    title: this.donor.title,
                    firstName: this.donor.firstName,
                    middleName: this.donor.middleName,
                    lastName: this.donor.lastName,
                    suffix: this.donor.suffix,
                    email: this.donor.email,
                    phone: this.donor.phone,
                    phoneOptIn: this.donor.phoneOptIn,
                    isOrganization: this.donor.organizationName ? true : false,
                    organizationName: this.donor.organizationName,
                    crmKey: this.donor.crmKey,
                    billingAddress: this.donor.billingAddress ? {
                        address1: this.donor.billingAddress?.address1,
                        address2: this.donor.billingAddress?.address2,
                        city: this.donor.billingAddress?.city,
                        state: this.donor.billingAddress?.state,
                        postal: this.donor.billingAddress?.postal?.toString(),
                        countryString: this.donor.billingAddress?.countryString,
                        addressType: AddressType.Billing,
                    } : null,
                    shippingAddress: this.donor.shippingAddress ? {
                        address1: this.donor.shippingAddress?.address1,
                        address2: this.donor.shippingAddress?.address2,
                        city: this.donor.shippingAddress?.city,
                        state: this.donor.shippingAddress?.state,
                        postal: this.donor.shippingAddress?.postal?.toString(),
                        countryString: this.donor.shippingAddress?.countryString,
                        addressType: AddressType.Shipping,
                    } : null
                },
                tribute: this.hasTribute ? {
                    tributeFirstName: this.tribute.tributeFirstName,
                    tributeLastName: this.tribute.tributeLastName,
                    tributeAddress: this.tribute.tributeAddress?.address1,
                    tributeCity: this.tribute.tributeAddress?.city,
                    tributeState: this.tribute.tributeAddress?.state,
                    tributeCountry: this.tribute.tributeAddress?.countryString,
                    tributePostal: this.tribute.tributeAddress?.postal?.toString(),
                    acknowledgeeFirstName: this.tribute.acknowledgeeFirstName,
                    acknowledgeeLastName: this.tribute.acknowledgeeLastName,
                    sendByEmail: this.tribute.sendByEmail,
                    acknowledgeeEmailAddress: this.tribute.acknowledgeeEmailAddress,
                    sendByPostal: this.tribute.sendByPostal,
                    acknowledgeeAddress: this.tribute.acknowledgeeAddress?.address1,
                    acknowledgeeCity: this.tribute.acknowledgeeAddress?.city,
                    acknowledgeeState: this.tribute.acknowledgeeAddress?.state,
                    acknowledgeeCountry: this.tribute.acknowledgeeAddress?.countryString,
                    acknowledgeePostal: this.tribute.acknowledgeeAddress?.postal?.toString(),
                    message: this.tribute.message,
                    isInHonorOf: this.tribute.isInHonorOf,
                    isInMemoryOf: this.tribute.isInMemoryOf,
                    NameOrOccasion: this.tribute.NameOrOccasion,
                } : null,
                publicId: this.pageResponse.publicId,
                pageRequestId: this.pageResponse.pageRequestId,
                nonce: this.pageResponse.nonce,
                amount: this.totalAmount,
                projects: this.gift.projectSplits,
                segment: this.page.pageSettings.motivationCode,
                segmentOverrideCode: this.page.segmentOverrideCode,
                isRecurring: this.gift.frequency != null && this.gift.frequency !== Frequency.OneTime,
                frequency: this.gift.frequency,
                startDate: this.gift.startDate,
                donorPaidCosts: this.gift.coverCosts,
                isAnonymous: false,
                paymentMethodType: this.gift.paymentMethodType,
                creditCardType: this.gift.creditCardType,
                paymentMethodId: this.gift.paymentMethodId,
                giftAidRequested: false,
                premiumId: this.gift.premium?.id,
                doubleTheDonationCompanyId: null,
                testMode: this.previewMode,
                doublethedonation_company_id: this.doubleTheDonationCompanyId,
                doublethedonation_entered_text: this.doubleTheDonationEnteredText,
                visitorId: getCookie('vcrmvid'),
                comments: this.gift.comments,
                paymentId: this.paymentId,
            } as DonateRequest;
        },

        async submitDonation() {
            this.isProcessing = true;

            //allow validation to complete before submitting
            this.validating = true;
            await nextTick();
            this.validating = false;

            //if there are errors, do not submit
            if (this.errors.length > 0) {
                this.isProcessing = false;
                return;
            }

            const publicApi = new publicApiService(this.apiBaseUrl);
            publicApi.submitDonation(this.getRequest(), this.organizationId)
                .then((response) => {
                    this.handleSuccess(response);
                })
                .catch((error) => {
                    this.handleFailure(error);
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },

        handleSuccess(response: DonateResponse) {
            if (response.requireAction) {
                // paymentComponent watches this value and will confirm card with stripe
                this.submissionRequiresAction = true;
                this.paymentClientSecret = response.paymentClientSecret;
                return;
            }

            this.transactionId = response.transactionId;
            this.success = response.success;

            if (this.success && this.page.useTrackingPixel) {
                const trackingPixel = new trackingPixelService();
                trackingPixel.trackEvent(TrackingEventType.FormSubmit, {
                    formId: this.page.id?.toString() ?? "",
                    formName: this.page.name,
                });
            }
        },

        handleFailure(error) {
            console.log(error);

            //if the error is AxiosError, add the toast message
            if (error?.message) {
                this.addToast(
                    `${error?.message}`,
                    ToastType.Error
                );
            }
            else if (error?.response.data[""]) {
                this.addToast(
                    `${error?.response.data[""][0]}`,
                    ToastType.Error
                );
            } else if (error?.response.data) {
                if (error.response.data.errors) {
                    this.setModelState(error.response.data.errors);
                }
                else {
                    this.setModelState(error.response.data);
                }
            } else if (error) {
                this.addToast(
                    error,
                    ToastType.Error
                );
            }
            else {
                this.addToast(
                    "Uh oh! There was an unexpected error. Please try again soon.",
                    ToastType.Error
                );
            }
        },

        setModelState(modelState: any): void {
            this.modelState = modelState;
        },

        addToast(text: string, type?: ToastType | null, title?: string | null, duration?: number | null, hasIcon?: boolean | null, iconClass?: string | null, hasCloseButton?: boolean | null) {

            const toast: Toast = {
                id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                text: text,
                title: title ?? null,
                type: type ?? ToastType.Info,
                duration: duration ?? 5000,
                hasIcon: hasIcon ?? false,
                iconClass: iconClass ?? "",
                hasCloseButton: hasCloseButton ?? true
            }

            this.toasts.push(toast);
        },

        clearToast(id: string) {
            const index = this.toasts.findIndex((toast) => toast.id === id);
            this.toasts.splice(index, 1);
        },
    },
    share: {
        enable: false,
    },
});
