import { AccessLevel } from "@/enums/AccessLevel";
import { Role } from "@/enums/Role";
import { Permission } from "@/interfaces";

export class PermissionHelper {
    public static HasPermission (userPermissions: Permission[], targerPermission: Permission): boolean {
        //check if user is a admin/super admin
        const isAdmin = this.IsAdmin(userPermissions);
        if (isAdmin) {
            return true;
        }

        //check if user has permissions
        const hasPermission = this.HasUserPermission(userPermissions, targerPermission);

        return hasPermission;
    }

    public static HasPermissions (userPermissions: Permission[], targerPermissions: Permission[]): boolean {
        //check if user is a admin/super admin
        const isAdmin = this.IsAdmin(userPermissions);
        if (isAdmin) {
            return true;
        }

        //check if user has permissions
        const hasPermission = targerPermissions.every(p => this.HasUserPermission(userPermissions, p));

        return hasPermission;
    }

    public static IsSystemAdmin(userPermissions: Permission[]): boolean {
        const systemAdminRoles = [Role.System_Admin, Role.System_SuperAdmin];
        const isSystemAdmin = userPermissions.some(p => systemAdminRoles.includes(p.role));
        return isSystemAdmin;
    }

    static IsAdmin(userPermissions: Permission[]): boolean {
        const adminRoles = [Role.Org_Admin, Role.Org_SuperAdmin, Role.System_Admin, Role.System_SuperAdmin];
        const isAdmin = userPermissions.some(p => adminRoles.includes(p.role));
        return isAdmin;
    }

    private static HasUserPermission (userPermissions: Permission[], targerPermission: Permission): boolean {
        const hasPermission = userPermissions.some(p => {
            if (p.role !== targerPermission.role) {
                return false;
            }

            switch(p.accessLevel){
                case AccessLevel.ViewAndEdit: {
                    return true;
                }

                case AccessLevel.View: {
                    return targerPermission.accessLevel == AccessLevel.ViewAndEdit ? false : true;
                }

                case AccessLevel.None:
                default: {
                    return false;
                }
            }
        });

        return hasPermission;
    }
}
