// Close item if you click outside its bounds
export const clickOutsideElement = {
    beforeMount: (element, binding) => {
        element.clickOutsideElementEvent = event => {
            if (!(element == event.target || element.contains(event.target))) {
                binding.value();
            }
        };

        document.addEventListener('click', element.clickOutsideElementEvent);
    },

    unmounted: element => {
        document.removeEventListener('click', element.clickOutsideElementEvent);
    }
};