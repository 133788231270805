import { EmailDesign } from "@/stores/emailBuilderStore";

export const DefaultText = (design: EmailDesign, html: string) => {
    return {
        type: "mailup-bee-newsletter-modules-text",
        descriptor: {
            text: {
                html: html,
                style: {
                    color: "#555555",
                    "line-height": "120%",
                    "font-family": "inherit",
                },
                computedStyle: {
                    linkColor: design.primaryColor,
                },
            },
            style: {
                "padding-top": "10px",
                "padding-right": "10px",
                "padding-bottom": "10px",
                "padding-left": "10px",
            },
            computedStyle: {
                hideContentOnMobile: false,
            },
            mobileStyle: {},
        },
        locked: false,
    };
};
