export const DefaultDivider = {
    type: "mailup-bee-newsletter-modules-divider",
    descriptor: {
        divider: {
            style: {
                "border-top": "1px solid #BBBBBB",
                width: "100%",
            },
        },
        style: {
            "padding-top": "10px",
            "padding-right": "10px",
            "padding-bottom": "10px",
            "padding-left": "10px",
        },
        mobileStyle: {},
        computedStyle: {
            align: "center",
            hideContentOnMobile: false,
        },
    },
    locked: false,
};
