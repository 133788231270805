import { EmailDesign } from "@/stores/emailBuilderStore";
import { DefaultTemplate } from "./DefaultTemplate";
import { DefaultLogo } from "./DefaultLogo";
import { DefaultText } from "./DefaultText";
import { DefaultDivider } from "./DefaultDivider";
import { DefaultButton } from "./DefaultButton";

export const DefaultNewRecurringScheduleEmail = (design: EmailDesign) => {
    return DefaultTemplate(design, [
        DefaultLogo(design),
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><strong>Thanks for setting up a recurring gift!</strong></p><p style="text-align: center; line-height: 14px; word-break: break-word;" data-renderer-start-pos="8929" data-mce-style="text-align: center; line-height: 14px;">Ongoing support from donors like you allow {{OrganizationName}} to continue making a positive impact on the world.</p></div>'
        ),
        DefaultDivider,
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px;"><strong>Organization Name</strong><br>{{OrganizationName}}<br><br><strong>Gift Amount</strong><br>{{RecurringGiftAmount}}<br><br><strong>Payment Method</strong><br>{{RecurringGiftPaymentLast4}}<br><br><strong>Frequency</strong><br>{{RecurringFrequency}}<br><br><strong>Next Charge Date</strong><br>{{NextChargeDate}}<br><br><strong>Project Allocation(s)</strong><br>{{Projects}}</p></div>'
        ),
        DefaultDivider,
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><strong data-renderer-mark="true">Manage Your Donation</strong>&nbsp;</p><p style="text-align: center; line-height: 14px; word-break: break-word;" data-renderer-start-pos="9306" data-mce-style="text-align: center; line-height: 14px;">Easily update your contact information, make changes to your recurring gift, and have receipts resent to you by email.</p></div>'
        ),
        DefaultButton(design, "Donor Portal Login", "{{DonorPortalLogin}}"),
    ]);
};
