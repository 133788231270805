import { ICustomComponent, IPage } from "@/builder/interfaces";
import { getEmptyComponent } from "@/builder/utilities";
import { LayoutObject } from "@stripe/stripe-js";
import { ComponentType } from "../../enums";

export interface IPaymentComponent extends ICustomComponent {
    controls: {
        canMove: true;
        canEdit: true;
        canDelete: true;
        allowChildren: false;
        allowMultiple: false;
    };
    customData: {
        currency: string;
        paymentMethods: string[],
        enableWallets: boolean;
        layout: LayoutObject;
    };
}

export const getPaymentComponent = (page: IPage) => getEmptyComponent(ComponentType.Payment, page);
