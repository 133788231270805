import { EmailDesign } from "@/stores/emailBuilderStore";
import { DefaultBody } from "./DefaultBody";
import { DefaultTemplate } from "./DefaultTemplate";
import { DefaultLogo } from "./DefaultLogo";
import { DefaultText } from "./DefaultText";
import { DefaultDivider } from "./DefaultDivider";
import { DefaultButton } from "./DefaultButton";

export const DefaultUpdatedRecurringScheduleEmail = (design: EmailDesign) => {
    return DefaultTemplate(design, [
        DefaultLogo(design),
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><strong>There was an update to your recurring gift</strong></p><p style="text-align: center; line-height: 14px; word-break: break-word;" data-renderer-start-pos="8929" data-mce-style="text-align: center; line-height: 14px; word-break: break-word;">Please review the updated details of your recurring gift.</p></div>'
        ),
        DefaultDivider,
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word;"><strong>Organization Name</strong><br>{{OrganizationName}}</p><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word;">&nbsp;</p><p data-renderer-start-pos="11876" style="line-height: 14px; word-break: break-word;" data-mce-style="line-height: 14px;"><strong data-renderer-mark="true">Recurring Gift Status</strong></p><p data-renderer-start-pos="11899" style="line-height: 14px; word-break: break-word;" data-mce-style="line-height: 14px;">{{RecurringGiftStatus}}</p><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word;"><br><strong>Gift Amount</strong><br>{{RecurringGiftAmount}}<br><br><strong>Payment Method</strong><br>{{RecurringGiftPaymentLast4}}</p><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word;">&nbsp;</p><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word;"><strong>Frequency</strong><br>{{RecurringFrequency}}<br><br><strong>Next Charge Date</strong><br>{{NextChargeDate}}<br><br><strong>Project Allocation(s)</strong><br>{{Projects}}</p></div>'
        ),
        DefaultDivider,
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><strong data-renderer-mark="true">Manage Your Donation</strong>&nbsp;</p><p style="text-align: center; line-height: 14px; word-break: break-word;" data-renderer-start-pos="9306" data-mce-style="text-align: center; line-height: 14px;">Easily update your contact information, make changes to your recurring gift, and have receipts resent to you by email.</p></div>'
        ),
        DefaultButton(design, "Donor Portal Login", "{{DonorPortalLogin}}"),
    ]);
};
