import {
    Action,
    ActionTrigger,
    ButtonActionType,
    getButtonComponent,
    IButtonComponent,
} from "@/builder/components/button/ButtonInterface";
import {
    getButtonGroupComponent,
    IButtonGroupComponent,
} from "@/builder/components/buttonGroup/ButtonGroupInterface";
import {
    getIconComponent,
    IIconComponent,
} from "@/builder/components/icon/IconInterface";
import {
    getPaymentComponent,
    IPaymentComponent,
} from "@/builder/components/payment/PaymentInterface";
import {
    ComponentStyles,
    GiftArrayOption,
    IColumnComponent,
    IConfirmationComponent,
    ICoverCostComponent,
    ICustomComponent,
    IDonationBlockComponent,
    IFormComponent,
    IFormStepComponent,
    IFrequencyComponent,
    IHeaderComponent,
    IHtmlComponent,
    IImageComponent,
    IPage,
    PageDesign,
    IPremiumComponent,
    IProgressBarComponent,
    ProjectSettings,
    ISectionComponent,
    ITemplate,
    ITextComponent,
    ITributeComponent,
    IVideoComponent,
    PremiumResponseModel,
    ProjectSplit,
    SmartGiftArraySettings,
    SmartGiftArrayOption,
} from "@/builder/interfaces/index";
import {
    BuilderTypes,
    Alignment,
    ComponentType,
    ConfirmationType,
    ContainerType,
    FieldStyle,
    FormType,
    FrequencyDisplayType,
    GiftArrayType,
    MergeTag,
    PageType,
    PartialDisplayType,
    PaymentLayout,
    PaymentMethod,
    ProjectDisplayType,
    SectionLayoutType,
    TributeType,
    DeviceDisplayType,
    SmartGiftArrayBasis,
    GoalBasis,
    Position
} from "./enums";
import {
    getTextInputComponent,
    InputType,
} from "@/builder/components/textInput/TextInputInterface";
import OrganizationDesignSettings from "../interfaces/OrganizationDesignSettings";
import { Frequency, frequencyDisplayName, IntegrationType, PaymentMethodType } from "@/enums";
import LookupResponse from "@/interfaces/responses/LookupResponse";

import {
    getFooterComponent,
    IFooterComponent,
} from "@/builder/components/footer/IFooterInterface";
import merge from "lodash.merge";
import { useGlobalStore } from "../stores/globalStore";
import { useBuilderStore } from "./stores/builderStore";
import { useRenderStore } from "./stores/renderStore";
import { ToastType } from "../components/shared/toast/interfaces";
import AddressRequest from "../interfaces/requests/AddressRequest";
import { ISpacerComponent } from "./components/spacer/SpacerInterface";
import { IDividerComponent } from "./components/divider/DividerInterface";

export const EMPTY_STYLES = <ComponentStyles>{
    cssClass: null,
    color: null,
    backgroundColor: null,
    fontColor: null,
    fontSize: null,
    fontFamily: null,
    textAlign: null,
    paddingLeft: null,
    paddingRight: null,
    paddingTop: null,
    paddingBottom: null,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    borderRadius: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: null,
    boxShadow: null,
    fullWidth: false,
    hoverColor: null,
    outlineColor: null,
    outlineWeight: null,
};

export const stylesToClasses = (styles: ComponentStyles) => {
    return {
        ...styles,
        color: styles.fontColor,
        backgroundColor: styles.backgroundColor,
        borderWidth: `${styles.outlineWeight}px`,
        borderRadius: `${styles.borderRadius}px`,
        borderColor: styles.outlineColor,
    };
};

export const generateUID = (prefix: string = "component_") =>
    `${prefix}${Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
    }`;

export const formatCurrency = (val: string) => {
    if (!val) return "0.00";

    const parsedAmount = parseFloat(
        `${!val ? 0 : val}`.replace(",", "")
    ).toFixed(2);

    return Number(parsedAmount).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

/**
 * @name: Find Path
 * @desc: finds the item's path
 **/
export const findPath = (
    array: ICustomComponent[],
    id: string,
    index: number,
    path: any[]
) => {
    if (array[index].id === id) {
        path.push({ id: array[index].id, index: index });
    } else if (array[index].components.length) {
        path.push({ id: array[index].id, index: index });

        array[index].components.forEach((_, i, a) => {
            findPath(a, id, i, path);
        });

        if (path[path.length - 1].id === array[index].id) path.pop();
    }
};

/**
 * @name: Get Index Path
 * @desc: finds and returns an array of indexes and component identifiers.
 *         this is used to determine the path of an item,
 *         and used to move or delete an item from the parent.
 * */
export const getIndexPath = (array: ICustomComponent[], id: string) => {
    const path: any[] = [];
    array.forEach((_, index, arr) => findPath(arr, id, index, path));
    return path;
};

/**
 * @name: Flatten Array
 * @desc: Flattens an array of N depth for simpler and faster searches.
 * @param: array: any[]
 * @returns: any[]
 **/
export const flattenArray = (array: any[]): any[] => {
    let newArray: any[] = [];
    for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i])) {
            newArray = newArray.concat(flattenArray(array[i]));
        } else {
            newArray.push(array[i]);
            if (Array.isArray(array[i].components)) {
                newArray = newArray.concat(flattenArray(array[i].components));
            }
        }
    }
    return newArray;
};

export const hexToRgbA = (hex: string, a: number) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        /*return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';*/

        const rgba = {
            r: (c >> 16) & 255,
            g: (c >> 8) & 255,
            b: c & 255,
            a: a,
        };

        return rgbaToString(rgba);
    }
    throw new Error("Bad Hex");
};

export const rgbaToString = (rgba: any) => {
    return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
};

export const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
        ? rgbToString({
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        })
        : null;
};

export const rgbToString = (rgb: any) => {
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};

export const getPaymentMethodType = (paymentMethod: string) => {
    //return paymentMethod enum based on string
    if (paymentMethod === PaymentMethod.Card) {
        return PaymentMethodType.Credit;
    }

    if (paymentMethod === PaymentMethod.US_Bank_Account) {
        return PaymentMethodType.ACH;
    }

    return PaymentMethodType.Credit;
};

export const amountToNumber = (amount: string | number | null) => {
    //if amount is a number return it
    if (typeof amount === "number") return amount;

    //exit if amount is null or empty
    if (!amount || amount.length === 0) return 0;

    return Number.parseFloat(amount?.replace(",", "") ?? "0");
}

export const handleMergeTags = (text: string) => {

    //exit if text is null or empty
    if (!text || text.length === 0) return;

    const renderStore = useRenderStore();

    text = text.replace(
        `{*${MergeTag.Donor_FirstName}*}`,
        renderStore.donor.firstName
    );
    text = text.replace(
        `{*${MergeTag.Donor_LastName}*}`,
        renderStore.donor.lastName
    );
    text = text.replace(
        `{*${MergeTag.Donor_Address}*}`,
        addressToString(renderStore.donor.billingAddress)
    );
    text = text.replace(
        `{*${MergeTag.Donor_Email}*}`,
        renderStore.donor.email ?? "Not Provided"
    );

    text = text.replace(
        `{*${MergeTag.Transaction_ID}*}`,
        renderStore.transactionId ?? ""
    );
    text = text.replace(
        `{*${MergeTag.Transaction_TotalAmount}*}`,
        formatCurrency(renderStore.totalAmount as unknown as string)
    );

    text = text.replace(
        `{*${MergeTag.Transaction_Premium}*}`,
        premiumToString(renderStore.gift.premium));

    text = text.replace(
        `{*${MergeTag.Transaction_ProjectSplit}*}`,
        projectSplitsToString(renderStore.gift.projectSplits));

    text = text.replace(
        `{*${MergeTag.Transaction_Frequency}*}`,
        renderStore.gift.frequency
            ? frequencyDisplayName(renderStore.gift.frequency)
            : "");

    //TODO: add organization donor portal to render store and handle merge tag
    //text = text.replace(MergeTag.DonorPortal_Login,);

    return text;
};

export const getMergeTagList = () => {
    const mergeTagList = [
        {
            title: "Donor",
            menu: [
                {
                    value: MergeTag.Donor_FirstName,
                    title: "First Name",
                },
                {
                    value: MergeTag.Donor_LastName,
                    title: "Last Name",
                },
                {
                    value: MergeTag.Donor_Address,
                    title: "Address",
                },
                {
                    value: MergeTag.Donor_Email,
                    title: "Email",
                },
            ],
        },
        {
            title: "Transaction",
            menu: [
                {
                    value: MergeTag.Transaction_ID,
                    title: "Transaction ID",
                },
                {
                    value: MergeTag.Transaction_TotalAmount,
                    title: "Total Amount",
                },
                {
                    value: MergeTag.Transaction_Premium,
                    title: "Premium",
                },
                {
                    value: MergeTag.Transaction_ProjectSplit,
                    title: "Project Split",
                },
                {
                    value: MergeTag.Transaction_Frequency,
                    title: "Frequency",
                },
            ],
        },
        //{
        //    value: MergeTag.DonorPortal_Login,
        //    title: "Donor Portal Login",
        //},
    ];

    return mergeTagList;
};

export const addressToString = (address: AddressRequest | null) => {
    if (!address) return "Not Provided";

    //if none of the address fields are filled out, exit
    if (!address.address1 && !address.address2 && !address.city && !address.state && !address.postal && !address.countryString) return "Not Provided";

    return `${address.address1} ${address.address2} ${address.city}, ${address.state} ${address.postal} ${address.countryString}`;
};

export const projectSplitsToString = (projectSplits: ProjectSplit[]) => {
    let projectSplitsString = "";

    //if there are no project splits, exit
    if (!projectSplits || projectSplits.length === 0) return projectSplitsString;

    const zeroAmount = '0';
    projectSplits.forEach((projectSplit, index) => {
        projectSplitsString += `${projectSplit.projectName} - $${formatCurrency(projectSplit.amount ?? zeroAmount)}`;
        if (index < projectSplits.length - 1) {
            projectSplitsString += ", ";
        }
    });

    return projectSplitsString;
};

export const premiumToString = (premium?: PremiumResponseModel | null) => {
    let premiumString = "";

    if (premium) {
        premiumString = `${premium.name}`;
    }

    return premiumString;
};

export const getFormStep = (
    parentId: string,
    components: ICustomComponent[]
) => {
    if (parentId && components.length > 0) {
        const parentComponent = flattenArray(components).find(
            (x) => x.id === parentId
        ) as ICustomComponent;

        if (
            parentComponent !== undefined &&
            parentComponent.type === ComponentType.FormStep
        ) {
            const formStepComponent = parentComponent as IFormStepComponent;
            const form = flattenArray(components).find(
                (x) => x.type === ComponentType.Form
            ) as IFormComponent;

            //get the index of the form step in the form steps array
            const formStep = form.components.findIndex(
                (x) => x.id === formStepComponent.id
            );

            return formStep;
        }

        return null;
    }

    return null;
};

export const handlePrivateApiFailure = (error: any) => {
    const globalStore = useGlobalStore();
    const store = useBuilderStore();

    if (error?.response.data[""]) {
        globalStore.addToastError(`${error?.response.data[""][0]}`);
    } else if (error?.response.data) {
        store.setModelState(error.response.data);
        store.isValid = false;
    } else {
        globalStore.addToastError("Uh oh! There was an unexpected error. Please try again soon.");
    }
};

/**
 * @name: Set Component Defaults
 * @desc: ensures components have default values for properties that are added after the component is created.
 **/
export const setComponentDefaults = (
    components: ICustomComponent[],
    page: IPage
): void => {
    const flattenedComponents = flattenArray(components);

    for (let i = 0; i < flattenedComponents.length; i++) {
        const component = flattenedComponents[i];
        const controlsCopy = { ...component.controls };
        const stylesCopy = { ...component.styles };
        const customDataCopy = { ...component.customData };
        component.controls = {
            ...getEmptyComponent(component.type, page).controls,
            ...controlsCopy,
        };
        component.styles = {
            ...getEmptyComponent(component.type, page).styles,
            ...stylesCopy,
        };
        component.customData = {
            ...getEmptyComponent(component.type, page).customData,
            ...customDataCopy,
        };
    }
};

export const getEditorId = (id: string) => {
    return 'editor_' + id;
}

export const setNewComponentIds = (components: ICustomComponent[]) => {
    const flattenedComponents = flattenArray(components);

    for (let i = 0; i < flattenedComponents.length; i++) {

        const component = flattenedComponents[i];
        component.id = generateUID();
    }
};

export const removeOneTimeComponents = (components: ICustomComponent[], usedTypes: ComponentType[]) => {
    //get the ids of the components that match the used types inside the components array
    const usedIds = flattenArray(components)
        .filter(x => usedTypes.includes(x.type))
        .map(x => x.id);

    //loop through ids and delete component
    usedIds.forEach(id => {
        const component = getComponentById(components, id);
        deleteComponentById(components, id);
    });
};

export const deleteComponentById = (components: ICustomComponent[], id: string) => {
    const path = getIndexPath(components, id);
    const siblingComponents =
        path.length > 1
            ? getComponentById(components, path[path.length - 2].id)?.components
            : components;

    //if the path is empty, exit, the component no longer exists
    if (path.length === 0) return;

    const currentIndex = path[path.length - 1].index;

    siblingComponents.splice(currentIndex, 1);

    //return the closest sibling component id
    const beforeSibling = siblingComponents[currentIndex - 1]?.id;
    const afterSibling = siblingComponents[currentIndex]?.id;

    return beforeSibling ? beforeSibling : afterSibling;
};

export const getComponentById = (components: ICustomComponent[], id: string) => {
    const component = flattenArray(components)
        .find((x) => x.id === id) as ICustomComponent;

    return component;
};

export const pushErrorToComponents = (components: ICustomComponent[], error: string) => {
    //loop through components and push error
    components.forEach(component => {
        //only push error if it doesnt already exist
        if (component.errors && !component.errors.includes(error)) {
            component.errors.push(error);
        }
    });
};

export const removeErrorFromComponents = (components: ICustomComponent[], error: string) => {
    //loop through components and remove error
    components.forEach(component => {
        //only remove error if it exists
        if (component.errors && component.errors.includes(error)) {
            component.errors = component.errors.filter(x => x !== error);
        }
    });
};

export const format = (pattern) => (value) => {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, (_, index) => v[i++] || "#");
};

export const loadGoogleFont = (fontFamily: string) => {
    if (!fontFamily.includes("Ariel") || !fontFamily.includes("Helvetica Neue") || !fontFamily.includes("Verdana") ||
        !fontFamily.includes("TimesNewRoman") || !fontFamily.includes("Georgia")) {

        //get just font name
        const fontName = fontFamily.split(",")[0].replace(/'/g, "").replace(/"/g, "").trim();
        const fontId = fontName + "_google_font";

        //only load google font if it's not already loaded
        if (document.getElementById(fontId)) return;

        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = `https://fonts.googleapis.com/css?family=${fontName}:wght@300;400;500;600;700&display=swap`;
        link.id = fontId;
        document.head.appendChild(link);
    }
}

export const loadFontAwesome = () => {
    //check if font awesome css and js are loaded
    if (!document.getElementById("font-awesome-css")) {
        const fontAwesomeCss = document.createElement("link");
        fontAwesomeCss.id = "font-awesome-css";
        fontAwesomeCss.rel = "stylesheet";
        fontAwesomeCss.href = "https://use.fontawesome.com/releases/v5.0.13/css/all.css";
        document.head.appendChild(fontAwesomeCss);
    }

    if (!document.getElementById("font-awesome-js")) {
        const fontAwesomeJs = document.createElement("script");
        fontAwesomeJs.id = "font-awesome-js";
        fontAwesomeJs.src = "https://kit.fontawesome.com/110497f6f0.js";
        fontAwesomeJs.crossOrigin = "anonymous";
        document.head.appendChild(fontAwesomeJs);
    }
}

export const getCookie = (name: string) => {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length == 2) {
        if (parts[1].includes(';')) {
            return parts[1].split(';').shift();
        }
    }
}

export const setDesignDefaults = (
    page: IPage,
    designSettings: OrganizationDesignSettings
): void => {
    //only set the values if they are not already set
    page.pageDesign.websiteUrl = setDesignValue(
        page.pageDesign.websiteUrl,
        designSettings.websiteUrl
    );
    page.pageDesign.logoUrl = setDesignValue(
        page.pageDesign.logoUrl,
        designSettings.logoUrl
    );
    page.pageDesign.primaryColor = setDesignValue(
        page.pageDesign.primaryColor,
        designSettings.primaryColor
    );
    page.pageDesign.secondaryColor = setDesignValue(
        page.pageDesign.secondaryColor,
        designSettings.secondaryColor
    );
    page.pageDesign.backgroundColor = setDesignValue(
        page.pageDesign.backgroundColor,
        designSettings.backgroundColor
    );
    page.pageDesign.headerColor = setDesignValue(
        page.pageDesign.headerColor,
        designSettings.headerColor
    );
    page.pageDesign.headerTextColor = setDesignValue(
        page.pageDesign.headerTextColor,
        designSettings.headerTextColor
    );
    page.pageDesign.bodyTextColor = setDesignValue(
        page.pageDesign.bodyTextColor,
        designSettings.bodyTextColor
    );
    page.pageDesign.footerColor = setDesignValue(
        page.pageDesign.footerColor,
        designSettings.footerColor
    );
    page.pageDesign.footerTextColor = setDesignValue(
        page.pageDesign.footerTextColor,
        designSettings.footerTextColor
    );
    page.pageDesign.buttonColor = setDesignValue(
        page.pageDesign.buttonColor,
        designSettings.buttonColor
    );
    page.pageDesign.buttonTextColor = setDesignValue(
        page.pageDesign.buttonTextColor,
        designSettings.buttonTextColor
    );
    page.pageDesign.buttonHoverColor = setDesignValue(
        page.pageDesign.buttonHoverColor,
        designSettings.buttonHoverColor
    );
    page.pageDesign.buttonHoverTextColor = setDesignValue(
        page.pageDesign.buttonHoverTextColor,
        designSettings.buttonHoverTextColor
    );
    page.pageDesign.linkColor = setDesignValue(
        page.pageDesign.linkColor,
        designSettings.linkColor
    );
    page.pageDesign.fieldLabelColor = setDesignValue(
        page.pageDesign.fieldLabelColor,
        designSettings.fieldLabelColor
    );
    page.pageDesign.fieldBorderColor = setDesignValue(
        page.pageDesign.fieldBorderColor,
        designSettings.fieldBorderColor
    );
    page.pageDesign.borderRadius = setDesignValue(
        page.pageDesign.borderRadius,
        designSettings.borderRadius
    );
    page.pageDesign.fontFamily = setDesignValue(
        page.pageDesign.fontFamily,
        designSettings.fontFamily
    );
};

const setDesignValue = (currentValue: string | null, value: string | null) => {
    return currentValue ? currentValue : value;
};

export const setPageCssVariables = (pageDesign: PageDesign) => {
    document.documentElement.style.setProperty(
        "--body-bg-color",
        pageDesign.backgroundColor
    );
    document.documentElement.style.setProperty(
        "--body-text-color",
        pageDesign.bodyTextColor
    );

    document.documentElement.style.setProperty(
        "--header-bg-color",
        pageDesign.headerColor
    );
    document.documentElement.style.setProperty(
        "--header-text-color",
        pageDesign.headerTextColor
    );

    document.documentElement.style.setProperty(
        "--base-font-family",
        pageDesign.fontFamily
    );

    document.documentElement.style.setProperty(
        "--link-text-color",
        pageDesign.linkColor
    );

    document.documentElement.style.setProperty(
        "--primary-color",
        pageDesign.primaryColor
    );
    document.documentElement.style.setProperty(
        "--secondary-color",
        pageDesign.secondaryColor
    );

    document.documentElement.style.setProperty(
        "--button-color",
        pageDesign.buttonColor
    );
    
    document.documentElement.style.setProperty(
        "--button-hover-color",
        pageDesign.buttonHoverColor
    );

    document.documentElement.style.setProperty(
        "--button-text-color",
        pageDesign.buttonTextColor
    );

    document.documentElement.style.setProperty(
        "--button-hover-text-color",
        pageDesign.buttonHoverTextColor
    );

    document.documentElement.style.setProperty(
        "--footer-bg-color",
        pageDesign.footerColor
    );

    document.documentElement.style.setProperty(
        "--footer-text-color",
        pageDesign.footerTextColor
    );

    document.documentElement.style.setProperty(
        "--field-label-color",
        pageDesign.fieldLabelColor
    );

    document.documentElement.style.setProperty(
        "--input-border-color",
        pageDesign.fieldBorderColor
    );

    document.documentElement.style.setProperty(
        "--input-border-radius",
        pageDesign.borderRadius + "px"
    );
};

export const getTemplates = (page: IPage) => {
    return [
        {
            id: generateUID(),
            name: "image-and-text",
            label: "Image and Text",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Body],
            component: <ISectionComponent>{
                ...getEmptyComponent(ComponentType.Section, page),
                components: [
                    <IColumnComponent>{
                        ...getEmptyComponent(ComponentType.Column, page),
                        components: [
                            getSecondaryTextComponent(page),
                            getButtonComponent(page),
                        ],
                        customData: {
                            columnWidth: 6,
                        },
                        errors: [],
                    },
                    {
                        ...getEmptyComponent(ComponentType.Column, page),
                        components: [
                            <IImageComponent>{
                                ...getEmptyComponent(ComponentType.Image, page),
                                customData: {
                                    imageUrl: process.env.VUE_APP_ENVIRONMENT === "local" ? "https://raisedonorsdev.azureedge.net/public/raise-donors/template/template-img.png" : process.env.VUE_APP_CDN_URL + '/public/raise-donors/template/template-img.png',
                                    altText: "Picture of field worker",
                                },
                                styles: {
                                    fullWidth: true,
                                },
                            },
                        ],
                        customData: {
                            className: 6,
                        },
                        errors: [],
                    },
                ],
                customData: {
                    backgroundImage: null,
                    useMobileBackgroundImage: false,
                    mobileBackgroundImage: null,
                    className: null,
                    layoutType: SectionLayoutType.TwoColumns_50_50,
                    deviceDisplay: DeviceDisplayType.All
                },
                errors: [],
            }
        },
        {
            id: generateUID(),
            name: "blank-section",
            label: "Blank Section",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Body],
            component: getEmptyComponent(ComponentType.Section, page)
        },
        {
            id: generateUID(),
            name: "blank-form",
            label: "Blank Form",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Column],
            component: getEmptyComponent(ComponentType.Form)
        },
        {
            id: generateUID(),
            name: "blank-form-step",
            label: "Blank Form Step",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form],
            component: <ICustomComponent>{
                ...getEmptyComponent(ComponentType.FormStep, page),
            }
        },
        {
            id: generateUID(),
            name: "text",
            label: "Text",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
            ],
            component: getSecondaryTextComponent(page)
        },
        {
            id: generateUID(),
            name: "text-input",
            label: "Text Input",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            component: getTextInputComponent()
        },
        {
            id: generateUID(),
            name: "image",
            label: "Image",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Column, ContainerType.Confirmation],
            component: getSecondaryImageComponent(page)
        },
        {
            id: generateUID(),
            name: "video",
            label: "Video",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Column, ContainerType.Confirmation],
            component: getVideoComponent(page)
        },
        {
            id: generateUID(),
            name: "html",
            label: "HTML",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Column, ContainerType.Confirmation],
            component: getHTMLComponent(page)
        },
        {
            id: generateUID(),
            name: "donation-block",
            label: "Donation Block",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            component: getDonationInputComponent(page)
        },
        {
            id: generateUID(),
            name: "button",
            label: "Button",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
            ],
            component: getButtonComponent(page)
        },
        {
            id: generateUID(),
            name: "button-group",
            label: "Button Group",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
            ],
            component: getButtonGroupComponent(page)
        },
        {
            id: generateUID(),
            name: "icon",
            label: "Icon",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
                ContainerType.Confirmation,
            ],
            component: getIconComponent(page)
        },
        {
            id: generateUID(),
            name: "payment-block",
            label: "Payment Block",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            component: getPaymentComponent(page)
        },
        {
            id: generateUID(),
            name: "frequency",
            label: "Frequency",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            component: getEmptyComponent(ComponentType.Frequency, page)
        },
        {
            id: generateUID(),
            name: "cover-cost",
            label: "Cover Cost",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            component: getEmptyComponent(ComponentType.CoverCost, page)
        },
        {
            id: generateUID(),
            name: "premium",
            label: "Premiums",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            component: getEmptyComponent(ComponentType.Premium, page)
        },
        {
            id: generateUID(),
            name: "footer",
            label: "Footer",
            category: "Basic",
            validPageTypes: [PageType.Full],
            validContainers: [ContainerType.Body],
            component: getFooterComponent(page)
        },
        {
            id: generateUID(),
            name: "tribute",
            label: "Tribute",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Form_Step,
            ],
            component: getEmptyComponent(ComponentType.Tribute, page)
        },
        {
            id: generateUID(),
            name: "double-the-donation",
            label: "Double The Donation",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [ContainerType.Form_Step],
            validIntegration: IntegrationType.DoubleTheDonation,
            component: getEmptyComponent(ComponentType.DoubleTheDonation, page)
        },
        {
            id: generateUID(),
            name: "spacer",
            label: "Spacer",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
            ],
            component: getEmptyComponent(ComponentType.Spacer, page)
        },
        {
            id: generateUID(),
            name: "divider",
            label: "Divider",
            category: "Basic",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
            ],
            component: getEmptyComponent(ComponentType.Divider, page)
        },
        {
            id: generateUID(),
            name: "progress-bar",
            label: "Progress Bar",
            category: "Giving",
            validPageTypes: [PageType.Full, PageType.Partial],
            validContainers: [
                ContainerType.Column,
                ContainerType.Form_Step,
            ],
            component: getEmptyComponent(ComponentType.ProgressBar, page)
        }
    ] as ITemplate[];
};

export const inputDefaults = {
    name: {
        type: InputType.Name,
        titleLabel: "Title",
        titlePlaceholder: "Title",
        firstNameLabel: "First Name",
        firstNamePlaceholder: "First",
        lastNameLabel: "Last Name",
        lastNamePlaceholder: "Last",
        middleNameLabel: "Middle Name",
        middleNamePlaceholder: "Middle",
        suffixLabel: "Suffix",
        suffixPlaceholder: "Suffix",
        businssNameLabel: "Business Name",
        businessNamePlaceholder: "Business Name",
        required: true,
    },
    email: {
        type: InputType.Email,
        label: "Email",
        placeholder: "email@example.com",
        required: true,
    },
    address: {
        type: InputType.Address,
        billingAddressLabel: "Address",
        billingAddress1Placeholder: "Street address",
        billingAddress2Placeholder: "Apartment or suite number",
        billingCityPlaceholder: "City",
        billingStatePlaceholder: "State",
        billingPostalPlaceholder: "Zip Code",
        billingCountryPlaceholder: "Country",
        required: true,
    },
};

const getSimpleGivingForm = (page: IPage) => {
    const formStepId = generateUID();
    return {
        ...getEmptySectionComponent(page),
        components: [
            {
                ...getEmptyColumnComponent(page),
                components: [
                    {
                        ...getEmptyComponent(ComponentType.Form, page),
                        components: [
                            {
                                ...getEmptyComponent(ComponentType.FormStep, page),
                                id: formStepId,
                                components: [
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: formStepId,
                                        customData: {
                                            ...inputDefaults.name,
                                        },
                                    }),
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: formStepId,
                                        customData: {
                                            ...inputDefaults.email,
                                        },
                                    }),
                                    {
                                        ...getEmptyComponent(ComponentType.Frequency, page),
                                        parentId: formStepId,
                                    },
                                    {
                                        ...getEmptyComponent(ComponentType.DonationBlock, page),
                                        parentId: formStepId,
                                    },
                                    {
                                        ...getEmptyComponent(ComponentType.Payment, page),
                                        parentId: formStepId,
                                    },
                                    {
                                        ...getEmptyComponent(ComponentType.CoverCost, page),
                                        parentId: formStepId,
                                    },
                                ],
                                customData: {
                                    title: "Simple Form"
                                }
                            },
                            getEmptyComponent(ComponentType.Confirmation, page),
                        ],
                    }
                ],
                customData: {
                    columnWidth: 12,
                },
            },
        ],
    };
};

const getModalGivingForm = (page: IPage) => {
    const firstFormStepId = generateUID();
    const secondFormStepId = generateUID();
    const thirdFormStepId = generateUID();
    return {
        ...getEmptySectionComponent(page),
        components: [
            {
                ...getEmptyColumnComponent(page),
                components: [
                    {
                        ...getEmptyComponent(ComponentType.Form, page),
                        components: [
                            {
                                ...getEmptyComponent(ComponentType.FormStep, page),
                                id: firstFormStepId,
                                components: [
                                    {
                                        ...getEmptyComponent(ComponentType.Frequency, page),
                                        parentId: firstFormStepId,
                                    },
                                    {
                                        ...getEmptyComponent(ComponentType.DonationBlock, page),
                                        parentId: firstFormStepId,
                                    },
                                    {
                                        ...getEmptyComponent(ComponentType.Text, page),
                                        parentId: firstFormStepId,
                                    },
                                ],
                            },
                            {
                                ...getEmptyComponent(ComponentType.FormStep, page),
                                id: secondFormStepId,
                                components: [
                                    {
                                        ...getEmptyComponent(ComponentType.CoverCost, page),
                                        parentId: secondFormStepId,
                                    },
                                    {
                                        ...getEmptyComponent(ComponentType.Payment, page),
                                        parentId: secondFormStepId,
                                    },
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: secondFormStepId,
                                        customData: {
                                            ...inputDefaults.name,
                                        },
                                    }),
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: secondFormStepId,
                                        customData: {
                                            ...inputDefaults.email,
                                        },
                                    }),
                                ],
                            },
                            {
                                ...getEmptyComponent(ComponentType.FormStep, page),
                                id: thirdFormStepId,
                                components: [
                                    {
                                        ...getEmptyComponent(ComponentType.Icon, page),
                                        parentId: thirdFormStepId,
                                    },
                                    {
                                        ...getEmptyComponent(ComponentType.Text, page),
                                        parentId: thirdFormStepId,
                                    },
                                ],
                            },
                            getEmptyComponent(ComponentType.Confirmation, page),
                        ],
                    },
                ],
                customData: {
                    columnWidth: 12,
                },
            },
        ],
    };
};

const getSimpleLeadForm = (page: IPage) => {
    const formStepId = generateUID();
    return {
        ...getEmptySectionComponent(page),
        components: [
            {
                ...getEmptyColumnComponent(page),
                components: [
                    {
                        ...getEmptyComponent(ComponentType.Form, page),
                        components: [
                            {
                                ...getEmptyComponent(ComponentType.FormStep, page),
                                id: formStepId,
                                components: [
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: formStepId,
                                        customData: {
                                            ...inputDefaults.name,
                                        },
                                    }),
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: formStepId,
                                        customData: {
                                            ...inputDefaults.email,
                                        },
                                    }),
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: formStepId,
                                        customData: {
                                            ...inputDefaults.address,
                                        },
                                    }),
                                ],
                            },
                            getEmptyComponent(ComponentType.Confirmation, page),
                        ],
                    },
                ],
                customData: {
                    columnWidth: 12,
                },
            },
        ],
    };
};

const getModalLeadForm = (page: IPage) => {
    const formStepId = generateUID();
    return {
        ...getEmptySectionComponent(page),
        components: [
            {
                ...getEmptyColumnComponent(page),
                components: [
                    {
                        ...getEmptyComponent(ComponentType.Form, page),
                        components: [
                            {
                                ...getEmptyComponent(ComponentType.FormStep, page),
                                id: formStepId,
                                components: [
                                    merge(getEmptyComponent(ComponentType.Text, page), {
                                        parentId: formStepId,
                                        customData: {
                                            value: `
                    <h1>About our org</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    `,
                                        },
                                    }),
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: formStepId,
                                        customData: {
                                            ...inputDefaults.name,
                                        },
                                    }),
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: formStepId,
                                        customData: {
                                            ...inputDefaults.email,
                                        },
                                    }),
                                    merge(getEmptyComponent(ComponentType.TextInput, page), {
                                        parentId: formStepId,
                                        customData: {
                                            ...inputDefaults.address,
                                        },
                                    }),
                                ],
                            },
                            getEmptyComponent(ComponentType.Confirmation, page),
                        ],
                    },
                ],
                customData: {
                    columnWidth: 12,
                },
            },
        ],
    };
};

const getDonationFormEmbedComponents = (page: IPage) => {
    const components: ICustomComponent[] = [];
    const form = getSimpleGivingForm(page);
    components.push(form);
    return components;
};

const getDonationModalComponents = (page: IPage) => {
    const components: ICustomComponent[] = [];
    const form = getModalGivingForm(page);
    components.push(form);
    return components;
};

const getLeadEmbedComponents = (page: IPage) => {
    const components: ICustomComponent[] = [];
    const form = getSimpleLeadForm(page);
    components.push(form);
    return components;
};

const getLeadModalComponents = (page: IPage) => {
    const components: ICustomComponent[] = [];
    const form = getModalLeadForm(page);
    components.push(form);
    return components;
};

const getPageDefaultSectionComponent = (page: IPage) => {
    return <ISectionComponent>{
        ...getEmptyComponent(ComponentType.Section, page),
        components: [
            <IColumnComponent>{
                ...getEmptyComponent(ComponentType.Column, page),
                components: [
                    <ITextComponent>{
                        ...getSecondaryTextComponent(page),
                        customData: {
                            value:
                                "<div><h2>We need more than a donor, we need a social investor.</h2><p>Join us on mission and help grow our talented and experienced core staff, purchase technology, form strategic partnerships, design support programs for individuals and families in crisis, execute our funding strategy, and pay operational expenses.\n" +
                                "\n" +
                                "Thank you for being on mission with us.</p></div>",
                        },
                        errors: [],
                    },
                    getButtonComponent(page),
                ],
                customData: {
                    columnWidth: 6,
                    horizontalAlignment: Alignment.Start,
                    verticalAlignment: Alignment.Start,
                },
                errors: [],
            },
            {
                ...getEmptyComponent(ComponentType.Column, page),
                components: [
                    <IImageComponent>{
                        ...getEmptyComponent(ComponentType.Image, page),
                        customData: {
                            imageUrl: process.env.VUE_APP_ENVIRONMENT === "local" ? "https://raisedonorsdev.azureedge.net/public/raise-donors/template/template-img.png" : process.env.VUE_APP_CDN_URL + '/public/raise-donors/template/template-img.png',
                            altText: "Picture of field worker",
                        },
                        errors: [],
                        styles: {
                            fullWidth: true,
                        }
                    },
                ],
                customData: {
                    columnWidth: 6,
                    horizontalAlignment: Alignment.Start,
                    verticalAlignment: Alignment.Start,
                },
                errors: [],
            },
        ],
        customData: {
            backgroundImage: null,
            useMobileBackgroundImage: false,
            mobileBackgroundImage: null,
            className: null,
            layoutType: SectionLayoutType.TwoColumns_50_50,
            deviceDisplay: DeviceDisplayType.All
        },
        errors: [],
    };
};
const getPageSimpleGiving = (page: IPage) => {
    const components: ICustomComponent[] = [];
    components.push(getEmptyComponent(ComponentType.Header, page));
    components.push(getPageDefaultSectionComponent(page));
    const form = getSimpleGivingForm(page);
    components.push(form);
    components.push(getEmptyComponent(ComponentType.Footer, page));
    return components;
};

const getPageModalGiving = (page: IPage) => {
    const components: ICustomComponent[] = [];
    components.push(getEmptyComponent(ComponentType.Header, page));
    components.push(getPageDefaultSectionComponent(page));
    const form = getModalGivingForm(page);
    components.push(form);
    components.push(getEmptyComponent(ComponentType.Footer, page));
    return components;
};

const getPageSimpleLead = (page: IPage) => {
    const components: ICustomComponent[] = [];
    components.push(getEmptyComponent(ComponentType.Header, page));
    components.push(getPageDefaultSectionComponent(page));
    const form = getSimpleLeadForm(page);
    components.push(form);
    components.push(getEmptyComponent(ComponentType.Footer, page));
    return components;
};

const getPageModalLead = (page: IPage) => {
    const components: ICustomComponent[] = [];
    components.push(getEmptyComponent(ComponentType.Header, page));
    components.push(getPageDefaultSectionComponent(page));
    const form = getModalLeadForm(page);
    components.push(form);
    components.push(getEmptyComponent(ComponentType.Footer, page));
    return components;
};

const BUILDER_TYPES_MAP = {
    [PageType.Partial]: {
        [FormType.Donation]: {
            [PartialDisplayType.Embed]: BuilderTypes.EmbedSimpleGiving,
            [PartialDisplayType.Modal]: BuilderTypes.ModalGiving,
        },
        [FormType.Lead]: {
            [PartialDisplayType.Embed]: BuilderTypes.EmbedSimpleLead,
            [PartialDisplayType.Modal]: BuilderTypes.ModalLead,
        },
    },
    [PageType.Full]: {
        [FormType.Donation]: {
            [PartialDisplayType.Embed]: BuilderTypes.PageSimpleGiving,
            [PartialDisplayType.Modal]: BuilderTypes.PageModalGiving,
        },
        [FormType.Lead]: {
            [PartialDisplayType.Embed]: BuilderTypes.PageSimpleLead,
            [PartialDisplayType.Modal]: BuilderTypes.PageModalLead,
        },
    },
};

export const getDefaultComponents = (page: IPage): ICustomComponent[] => {
    const pageType = page.pageType;
    const formType = page.formType;
    const partialDisplayType = page.partialDisplayType;

    const builderType =
        BUILDER_TYPES_MAP[pageType][formType][partialDisplayType];

    switch (builderType) {
        case BuilderTypes.EmbedSimpleGiving: {
            return getDonationFormEmbedComponents(page);
        }
        case BuilderTypes.ModalGiving: {
            return getDonationModalComponents(page);
        }
        case BuilderTypes.EmbedSimpleLead: {
            return getLeadEmbedComponents(page);
        }
        case BuilderTypes.ModalLead: {
            return getLeadModalComponents(page);
        }
        case BuilderTypes.PageSimpleGiving: {
            return getPageSimpleGiving(page);
        }
        case BuilderTypes.PageModalGiving: {
            return getPageModalGiving(page);
        }
        case BuilderTypes.PageSimpleLead: {
            return getPageSimpleLead(page);
        }
        case BuilderTypes.PageModalLead: {
            return getPageModalLead(page);
        }
        default:
            return [];
    }
};

/**
 * @name: Get Empty Component
 * @desc: Gets the components empty state by component type.
 **/
export const getEmptyComponent = (
    componentType: ComponentType,
    page: IPage | null = null
): ICustomComponent => {
    switch (componentType) {
        case ComponentType.Header:
            return <IHeaderComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Header,
                controls: {
                    canMove: false,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false
                },
                components: [] as ICustomComponent[],
                styles: {
                    ...EMPTY_STYLES,
                },
                customData: {
                    logoUrl: page?.pageDesign?.logoUrl,
                    logoAltText: null,
                    logoWidth: 200,
                    showDonorPortalLink: true,
                    showHelpLink: true,
                    links: [],
                },
                errors: [],
                label: "Header",
            };
        case ComponentType.Footer:
            return getFooterComponent(page) as IFooterComponent;
        case ComponentType.Section:
            return <ISectionComponent>{
                id: generateUID(),
                type: ComponentType.Section,
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: true
                },
                components: [
                    <IColumnComponent>{
                        ...getEmptyComponent(ComponentType.Column),
                        customData: {
                            columnWidth: 12,
                            horizontalAlignment: Alignment.Start,
                            verticalAlignment: Alignment.Start,
                        }
                    }
                ] as ICustomComponent[],
                styles: {
                    ...EMPTY_STYLES,
                },
                customData: {
                    layoutType: SectionLayoutType.OneColumn,
                    deviceDisplay: DeviceDisplayType.All,
                },
                errors: [],
                label: "Section",
            };
        case ComponentType.Column:
            return <IColumnComponent>{
                id: generateUID(),
                type: ComponentType.Column,
                controls: {
                    canMove: false,
                    canEdit: false,
                    canDelete: false,
                    allowChildren: true,
                    allowMultiple: true
                },
                styles: {
                    ...EMPTY_STYLES,
                },
                components: [] as ICustomComponent[],
                customData: {
                    columnWidth: null,
                    horizontalAlignment: Alignment.Start,
                    verticalAlignment: Alignment.Start,
                },
                errors: [],
                label: "Column",
            };
        case ComponentType.Text:
            return <ITextComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Text,
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true
                },
                components: [] as ICustomComponent[],
                styles: {
                    ...EMPTY_STYLES,
                },
                customData: {
                    value: null,
                    mergeTags: null,
                    timeout: null,
                },
                errors: [],
                label: "Text",
            };
        case ComponentType.Image:
            return <IImageComponent>{
                id: generateUID(),
                type: ComponentType.Image,
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true
                },
                components: [] as ICustomComponent[],
                styles: {
                    ...EMPTY_STYLES,
                },
                customData: {
                    imageUrl: process.env.VUE_APP_ENVIRONMENT === "local" ? "https://raisedonorsdev.azureedge.net/public/raise-donors/template/template-img.png" : process.env.VUE_APP_CDN_URL + '/public/raise-donors/template/template-img.png',
                    altText: "Picture of field worker",
                },
                errors: [],
                label: "Image",
            };
        case ComponentType.Button:
            return <IButtonComponent>{
                id: generateUID(),
                type: ComponentType.Button,
                styles: {
                    ...EMPTY_STYLES,
                    cssClass: "btn btn-primary",
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true
                },
                components: [] as ICustomComponent[],
                customData: {
                    text: "Learn More",
                    buttonSize: "btn-md",
                    action: {
                        type: ButtonActionType.Link,
                        values: "",
                        trigger: ActionTrigger.Click,
                    },
                },
                errors: [],
                label: "Button",
            };
        case ComponentType.ButtonGroup:
            return <IButtonGroupComponent>{
                id: generateUID(),
                type: ComponentType.ButtonGroup,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: true
                },
                components: [] as ICustomComponent[],
                customData: {},
                errors: [],
                label: "Button Group",
            };
        case ComponentType.Confirmation:
            return <IConfirmationComponent>{
                id: generateUID(),
                type: ComponentType.Confirmation,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: false,
                    canEdit: true,
                    canDelete: false,
                    allowChildren: true,
                    allowMultiple: false
                },
                components: [
                    <ITextComponent>{
                        id: generateUID(),
                        type: ComponentType.Text,
                        styles: {
                            ...EMPTY_STYLES,
                        },
                        controls: {
                            canMove: true,
                            canEdit: true,
                            canDelete: true,
                            allowChildren: false,
                            allowMultiple: true
                        },
                        components: [] as ICustomComponent[],
                        customData: {
                            value:
                                page?.formType === FormType.Donation
                                    ? "<p>Thank you for your donation!</p>"
                                    : "<p>Thank you for your submission!</p>",
                        },
                        errors: [],
                    },
                ] as ICustomComponent[],
                customData: {
                    type: ConfirmationType.Custom,
                    redirectUrl: null,
                },
                errors: [],
                label: "Confirmation Step",
            };
        case ComponentType.Form:
            return <IFormComponent>{
                id: generateUID(),
                type: ComponentType.Form,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: false
                },
                components: [
                    getEmptyComponent(ComponentType.FormStep),
                    getEmptyComponent(ComponentType.Confirmation),
                ] as ICustomComponent[],
                customData: {
                    fundraisingGoal: "",
                    expirationDate: "",
                    motivationCode: "",
                    segmentCode: "",
                    mediaOutlet: "",
                    thankYouMessage: "",
                    showDifferentMessageForRecurring: false,
                    confirmationEmailSubject: "",
                    confirmationEmailContent: "",
                    confirmationEmailAddress: "",
                    customSocialSharingMessage: false,
                    template: "",
                    showDonorPortalLink: false,
                    showHelpAndInfoLink: false,
                    fieldStyle: FieldStyle.Default,
                    buttons: {
                        submit: <IButtonComponent>{
                            id: generateUID(),
                            type: ComponentType.Button,
                            styles: {
                                ...EMPTY_STYLES,
                                cssClass: "btn btn-primary",
                            },
                            controls: {
                                canMove: false,
                                canEdit: true,
                                canDelete: false,
                                allowChildren: false,
                                allowMultiple: false
                            },
                            components: [] as ICustomComponent[],
                            customData: {
                                text: "Submit",
                                buttonSize: "btn-md",
                                icon: null,
                                action: <Action>{
                                    type: ButtonActionType.Submit,
                                    trigger: ActionTrigger.Click,
                                    values: null,
                                },
                            },
                            errors: [],
                        },
                        next: <IButtonComponent>{
                            id: generateUID(),
                            type: ComponentType.Button,
                            styles: {
                                ...EMPTY_STYLES,
                                cssClass: "btn btn-primary",
                            },
                            controls: {
                                canMove: false,
                                canEdit: true,
                                canDelete: false,
                                allowChildren: false,
                                allowMultiple: false
                            },
                            components: [] as ICustomComponent[],
                            customData: {
                                text: "Next",
                                buttonSize: "btn-md",
                                icon: null,
                                action: <Action>{
                                    type: ButtonActionType.NextStep,
                                    trigger: ActionTrigger.Click,
                                    values: null,
                                },
                            },
                            errors: [],
                        },
                        prev: <IButtonComponent>{
                            id: generateUID(),
                            type: ComponentType.Button,
                            styles: {
                                ...EMPTY_STYLES,
                                cssClass: "btn btn-primary",
                            },
                            controls: {
                                canMove: false,
                                canEdit: true,
                                canDelete: false,
                                allowChildren: false,
                                allowMultiple: false
                            },
                            components: [] as ICustomComponent[],
                            customData: {
                                text: "Back",
                                buttonSize: "btn-md",
                                icon: null,
                                action: <Action>{
                                    type: ButtonActionType.PrevStep,
                                    trigger: ActionTrigger.Click,
                                    values: null,
                                },
                            },
                            errors: [],
                        },
                        modalAlignment: Alignment.Center,
                        modal: <IButtonComponent>{
                            id: generateUID(),
                            type: ComponentType.Button,
                            styles: {
                                ...EMPTY_STYLES,
                                cssClass: "btn btn-primary",
                            },
                            controls: {
                                canMove: false,
                                canEdit: true,
                                canDelete: false,
                                allowChildren: false,
                                allowMultiple: false
                            },
                            components: [] as ICustomComponent[],
                            customData: {
                                text: "Open Modal",
                                buttonSize: "btn-md",
                                icon: null,
                                action: <Action>{
                                    type: ButtonActionType.Modal,
                                    trigger: ActionTrigger.Click,
                                    values: null,
                                },
                            },
                            errors: [],
                        },
                    },
                },
                errors: [],
                label: "Form",
            };
        case ComponentType.FormStep:
            return <IFormStepComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.FormStep,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: true,
                    allowMultiple: true
                },
                components: [],
                customData: {
                    title: 'New Step'
                },
                errors: [],
                label: "Form Step",
            };
        case ComponentType.TextInput:
            return getTextInputComponent();
        case ComponentType.Video:
            return <IVideoComponent>{
                id: generateUID(),
                styles: {
                    ...EMPTY_STYLES,
                },
                type: ComponentType.Video,
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true
                },
                components: [] as ICustomComponent[],
                customData: {
                    videoUrl: null,
                },
                errors: [],
                label: "Video",
            };
        case ComponentType.HTML:
            return <IHtmlComponent>{
                id: generateUID(),
                type: ComponentType.HTML,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true
                },
                components: [] as ICustomComponent[],
                customData: {
                    html: null,
                },
                errors: [],
                label: "HTML",
            };
        case ComponentType.DonationBlock:
            return <IDonationBlockComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.DonationBlock,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false
                },
                components: [] as ICustomComponent[],
                customData: {
                    giftArrayType: GiftArrayType.CustomAmount,
                    giftArray: [] as GiftArrayOption[],
                    fixedAmount: 0,
                    projectSettings: <ProjectSettings>{
                        showImage: false,
                        showGiftArray: false,
                        showDescription: false,
                        showCustomAmount: true,
                        allowMultipleProjects: false,
                        availableProjects: [] as LookupResponse[],
                        displayType: ProjectDisplayType.Dropdown,
                        showProjects: true,
                        projectLabel: "Project",
                    },
                    useRecurringArray: false,
                    recurringArray: [] as GiftArrayOption[],
                    useSmartGiftArray: false,
                    smartGiftArraySettings: <SmartGiftArraySettings>{
                        previewAmount: 25,
                        giftArray: [] as SmartGiftArrayOption[],
                        smartArrayBasis: SmartGiftArrayBasis.SuggestedAmount,
                    },
                    buttonSize: "btn-md"
                },
                errors: [],
                label: "Donation Block",
            };
        case ComponentType.Icon:
            return <IIconComponent>{
                id: generateUID(),
                type: ComponentType.Icon,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true
                },
                components: [] as ICustomComponent[],
                customData: {
                    icon: "circle-check",
                    size: 6
                },
                errors: [],
                label: "Icon",
            };
        case ComponentType.Payment:
            return <IPaymentComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Payment,
                styles: {
                    ...EMPTY_STYLES
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false
                },
                components: [],
                customData: {
                    currency: "usd",
                    paymentMethods: [
                        PaymentMethod.Card,
                        PaymentMethod.US_Bank_Account,
                    ],
                    enableWallets: false,
                    layout: {
                        type: PaymentLayout.Tabs,
                    }
                },
                errors: [],
                label: `Payment Block`,
            };
        case ComponentType.Frequency:
            return <IFrequencyComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Frequency,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false
                },
                components: [],
                customData: {
                    displayType: FrequencyDisplayType.Tabs,
                    availableFrequencies: [
                        Frequency.OneTime,
                        Frequency.Monthly,
                    ] as Frequency[],
                    canChooseStartDate: false,
                    checkboxText:
                        "Increase my impact, and make this a recurring gift.",
                    checkedByDefault: false,
                    buttonSize: "btn-md",
                },
                errors: [],
                label: "Frequency",
            };
        case ComponentType.CoverCost:
            return <ICoverCostComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.CoverCost,
                styles: {
                    ...EMPTY_STYLES,
                    backgroundColor: page?.pageDesign?.primaryColor,
                    borderRadius: page?.pageDesign?.borderRadius,
                    borderColor: page?.pageDesign?.fieldBorderColor,
                    fontColor: page?.pageDesign?.bodyTextColor,
                    fontFamily: page?.pageDesign?.fontFamily,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false
                },
                components: [],
                customData: {
                    coverCostText:
                        "Cover the processing fee so that 100% of my donation goes to the cause.",
                    showAmountPreview: true,
                },
                errors: [],
                label: "Cover Cost",
            };
        case ComponentType.Tribute:
            return <ITributeComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Tribute,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false
                },
                components: [],
                customData: {
                    isChecked: false,
                    tributeType: TributeType.Both,
                    tributeOptInText: "Dedicate my donation in honor or in memory of someone.",
                    tributeInformationLabel: "Tribute Information",
                    showTributeAddress: true,
                    hasAcknowledgement: true,
                    acknowledgeeInformationLabel: "Acknowledgee Information",
                    hasEmailAcknowledgement: true,
                    hasPostalAcknowledgement: true,
                },
                errors: [],
                label: "Tribute",
            };
        case ComponentType.DoubleTheDonation:
            return <ICustomComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.DoubleTheDonation,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false
                },
                components: [],
                customData: {
                    label: "Search for your employer to match your donation"
                },
                errors: [],
                label: "Double The Donation",
            };
        case ComponentType.Premium:
            return <IPremiumComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Premium,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false
                },
                components: [],
                customData: {
                    optInButton: <IButtonComponent>{
                        id: generateUID(),
                        type: ComponentType.Button,
                        styles: {
                            ...EMPTY_STYLES,
                            cssClass: "btn btn-primary",
                        },
                        controls: {
                            canMove: false,
                            canEdit: true,
                            canDelete: false,
                            allowChildren: false,
                            allowMultiple: false
                        },
                        components: [] as ICustomComponent[],
                        customData: {
                            text: "Opt In",
                            buttonSize: "btn-md",
                            icon: null,
                            action: <Action>{
                                type: ButtonActionType.Link,
                                trigger: ActionTrigger.Click,
                                values: null,
                            },
                        },
                        errors: [],
                    },
                    optOutButton: <IButtonComponent>{
                        id: generateUID(),
                        type: ComponentType.Button,
                        styles: {
                            ...EMPTY_STYLES,
                            cssClass: "btn btn-primary",
                        },
                        controls: {
                            canMove: false,
                            canEdit: true,
                            canDelete: false,
                            allowChildren: false,
                            allowMultiple: false
                        },
                        components: [] as ICustomComponent[],
                        customData: {
                            text: "Opt Out",
                            buttonSize: "btn-md",
                            icon: null,
                            action: <Action>{
                                type: ButtonActionType.Link,
                                trigger: ActionTrigger.Click,
                                values: null,
                            },
                        },
                        errors: [],
                    },
                    showName: true,
                    showSku: true,
                    showImage: true,
                    showDescription: true,
                    showOptIn: true,
                    showOptOut: true,
                    premiumArray: [] as LookupResponse[],
                },
                errors: [],
                label: "Premium Block",
            };
        case ComponentType.Spacer:
            return <ISpacerComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Spacer,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true
                },
                components: [],
                customData: {
                    topPadding: 10,
                    bottomPadding: 10,
                },
                errors: [],
                label: "Spacer",
            };
        case ComponentType.Divider:
            return <IDividerComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.Divider,
                styles: {
                    ...EMPTY_STYLES,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: true
                },
                components: [],
                customData: {
                    topPadding: 10,
                    bottomPadding: 10,
                    dividerColor: "#dcdbd9",
                    dividerWidth: 75,
                    dividerWeight: 1,
                    dividerAlignment: "center"
                },
                errors: [],
                label: "Divider",
            };
        case ComponentType.ProgressBar:
            return <IProgressBarComponent>{
                id: generateUID(),
                parentId: null,
                type: ComponentType.ProgressBar,
                styles: {
                    ...EMPTY_STYLES,
                    outlineWeight: null,
                    outlineColor: null,
                },
                controls: {
                    canMove: true,
                    canEdit: true,
                    canDelete: true,
                    allowChildren: false,
                    allowMultiple: false,
                },
                components: [],
                customData: {
                    label: "Progress Bar",
                    labelPosition: Position.Top,
                    goalBasis: GoalBasis.AmountRaised,
                    addAmount: null,
                    barHeight: 20,
                    displayThresholdEnabled: false,
                    displayThreshold: null,
                },
                errors: [],
                label: "Progress Bar",
            };
        default:
            console.error(
                "Uh oh, looks like someone forgot to add the component type to the getDefaultComponent list."
            );
            return {} as ICustomComponent;
    }
};

/**
 * TESTING DEFAULT COMPONENTS
 **/
const getPrimaryTextComponent = (page: IPage) =>
    <ITextComponent>{
        ...getEmptyComponent(ComponentType.Text, page),
        customData: {
            value: "<div><h2>Lorem ipsum dolor sit amet</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. <strong>Morbi viverra ante et neque pellentesque commodo.</strong></p></div>",
        },
    };

const getSecondaryTextComponent = (page: IPage) =>
    <ITextComponent>{
        ...getEmptyComponent(ComponentType.Text, page),
        customData: {
            value: "<div><h2>How can you help?</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p></div>",
        },
    };

const getHTMLComponent = (page: IPage) => ({
    ...getEmptyComponent(ComponentType.HTML, page),
    customData: {
        html: "<h1>hello world</h1>",
    },
});

const getDonationInputComponent = (page: IPage) => ({
    ...getEmptyComponent(ComponentType.DonationBlock, page),
    label: "Donation<span>Block</span>",
});

const getVideoComponent = (page: IPage) => ({
    ...getEmptyComponent(ComponentType.Video, page),
    customData: {
        videoUrl: "https://www.youtube.com/embed/MzYD56hKF-8",
    },
});

export const getEmptyColumnComponent = (page: IPage) =>
    <IColumnComponent>{
        ...getEmptyComponent(ComponentType.Column, page),
        customData: {
            horizontalAlignment: Alignment.Start,
            verticalAlignment: Alignment.Start,
        },
    };

export const getEmptySectionComponent = (page: IPage) =>
    <ISectionComponent>{
        ...getEmptyComponent(ComponentType.Section, page),
        components: [] as ICustomComponent[],
    };

const getPrimaryImageComponent = (page: IPage) =>
    <IImageComponent>{
        ...getEmptyComponent(ComponentType.Image, page),
        customData: {
            imageUrl:
                "https://images.unsplash.com/photo-1551855350-c86caeaf8707?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3000&q=80",
            altText: "Picture of field worker",
        },
    };

const getSecondaryImageComponent = (page: IPage) =>
    <IImageComponent>{
        ...getEmptyComponent(ComponentType.Image, page),
        customData: {
            altText: "Image",
        },
    };
