import { EmailDesign } from "@/stores/emailBuilderStore";

export const LastContainer = (design: EmailDesign) => {
    return {
        container: {
            style: {
                "background-color": "transparent",
                "background-image": "none",
                "background-position": "top left",
                "background-repeat": "no-repeat",
            },
        },
        content: {
            computedStyle: {
                hideContentOnDesktop: false,
                hideContentOnMobile: false,
                rowColStackOnMobile: true,
                rowReverseColStackOnMobile: false,
                verticalAlign: "top",
            },
            style: {
                "background-color": "transparent",
                "background-image": "none",
                "background-position": "top left",
                "background-repeat": "no-repeat",
                color: "#000000",
                width: design.contentAreaWidth,
            },
        },
    };
};
