import { EmailDesign } from "@/stores/emailBuilderStore";

export const DefaultButton = (
    design: EmailDesign,
    text: string,
    href: string
) => {
    return {
        type: "mailup-bee-newsletter-modules-button",
        descriptor: {
            button: {
                label: `<div class="txtTinyMce-wrapper" style="font-family: inherit;" data-mce-style="font-family: inherit;"><p dir="ltr" style="word-break: break-word;" data-mce-style="word-break: break-word;">${text}</p></div>`,
                href: href,
                target: "_blank",
                style: {
                    "font-family": "inherit",
                    "font-size": "14px",
                    "font-weight": "400",
                    "background-color": design.buttonColor,
                    "border-radius": "4px",
                    "border-top": "0px solid transparent",
                    "border-right": "0px solid transparent",
                    "border-bottom": "0px solid transparent",
                    "border-left": "0px solid transparent",
                    color: design.buttonTextColor,
                    "line-height": "200%",
                    "padding-top": "5px",
                    "padding-right": "20px",
                    "padding-bottom": "5px",
                    "padding-left": "20px",
                    width: "auto",
                    "max-width": "100%",
                    direction: "ltr",
                },
            },
            style: {
                "text-align": "center",
                "padding-top": "10px",
                "padding-right": "10px",
                "padding-bottom": "10px",
                "padding-left": "10px",
            },
            mobileStyle: {},
            computedStyle: {
                width: 156,
                height: 38,
                hideContentOnMobile: false,
            },
        },
        locked: false,
    };
};
