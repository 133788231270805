import { EmailDesign } from "@/stores/emailBuilderStore";
import { DefaultBody } from "./DefaultBody";
import { LastContainer } from "./LastContainer";

export const DefaultTemplate = (
    design: EmailDesign,
    modules: any[]
) => {
    return {
        page: {
            body: DefaultBody(design),
            description: "",
            rows: [
                {
                    columns: [
                        {
                            "grid-columns": 12,
                            modules: modules,
                            style: {
                                "background-color": "transparent",
                                "border-bottom": "0 solid transparent",
                                "border-left": "0 solid transparent",
                                "border-right": "0 solid transparent",
                                "border-top": "0 solid transparent",
                                "padding-bottom": "5px",
                                "padding-left": "0px",
                                "padding-right": "0px",
                                "padding-top": "5px",
                            },
                            uuid: "de6e5770-f804-49e1-b9ef-bea405585617",
                        },
                    ],
                    ...LastContainer(design),
                    empty: false,
                    locked: false,
                    synced: false,
                    type: "one-column-empty",
                },
            ],
            template: {
                version: "2.0.0",
            },
            title: "",
        },
        comments: {},
    };
};
