import { EmailDesign } from "@/stores/emailBuilderStore";

export const DefaultBody = (design: EmailDesign) => {
    return {
        container: {
            style: {
                "background-color": design.backgroundColor,
            },
        },
        content: {
            computedStyle: {
                align: design.contentAligment,
                linkColor: design.primaryColor,
                messageBackgroundColor: "transparent",
                messageWidth: design.contentAreaWidth,
            },
            style: {
                color: "#000000",
                "font-family": design.fontFamily,
            },
        },
        webFonts: [],
    };
};
