export enum Frequency {
    OneTime = 0,
    Weekly = 52,
    EveryOtherWeek = 26,
    Monthly = 12,
    Quarterly = 4,
    BiAnnualy = 2,
    Annualy = 1,
}

export const frequencyDisplayName = (frequency: Frequency) => {
    switch (frequency) {
        case Frequency.Weekly:
            return "Weekly";
        case Frequency.Monthly:
            return "Monthly";
        case Frequency.EveryOtherWeek:
            return "Every Other Week";
        case Frequency.Quarterly:
            return "Quarterly";
        case Frequency.BiAnnualy:
            return "Every Six Months";
        case Frequency.Annualy:
            return "Annually";
        default:
            return "One-Time";
    }
}
