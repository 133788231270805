
    import { defineComponent } from "vue";
    import { useRenderStore } from "../../../builder/stores/renderStore";
    import { useGlobalStore } from "../../../stores/globalStore";
    import { ToastPosition } from "./interfaces";
    import ToastItem from "./ToastItem.vue";

    export default defineComponent({
        name: 'ToastContainer',
        props: {
            position: {
                type: String,
                required: false,
                default: ToastPosition.BottomRight
            },
            storeSelection: {
                type: String,
                required: true,
                default: 'global'
            }
        },
        components: {
            ToastItem
        },
        setup(props) {
            switch (props.storeSelection) {
                case 'globalStore':
                    return {
                        store: useGlobalStore()
                    };
                case 'renderStore':
                    return {
                        store: useRenderStore()
                    };
                default:
                    return {
                        store: useGlobalStore()
                    };
            }
        },
        computed: {
            parsedPosition() {
                switch (this.position) {
                    case ToastPosition.TopLeft:
                        return 'toast-left top-5';
                    case ToastPosition.BottomLeft:
                        return 'toast-left bottom-5';
                    case ToastPosition.TopRight:
                        return 'toast-right top-5';
                    case ToastPosition.BottomRight:
                        return 'toast-right bottom-5';
                    default:
                        return 'toast-right bottom-5';
                }
            },
            toasts() {
                return this.store.toasts;
            }
        }
    });
