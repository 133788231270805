
    import { defineComponent, ref } from 'vue';
    import { ToastType } from '@/components/shared/toast/interfaces';
    import { useGlobalStore } from '../../../stores/globalStore';
    import { useRenderStore } from '../../../builder/stores/renderStore';

    export default defineComponent({
        name: 'VueToastItem',
        props: {
            id: {
                type: String,
                required: true
            },
            title: {
                type: String
            },
            text: {
                type: String,
                required: true
            },
            type: {
                type: String,
            },
            hasIcon: {
                type: Boolean
            },
            iconClass: {
                type: String
            },
            hasCloseButton: {
                type: Boolean
            },
            duration: {
                type: Number,
                required: true
            },
            storeSelection: {
                type: String,
                required: true,
                default: 'global'
            }
        },
        setup(props) {
            switch (props.storeSelection) {
                case 'global':
                    return {
                        store: useGlobalStore()
                    };
                case 'render':
                    return {
                        store: useRenderStore()
                    };
                default:
                    return {
                        store: useGlobalStore()
                    };
            }
        },
        data() {
            return {
                timeout: null as any
            }
        },
        mounted() {
            this.timeout = setTimeout(() => {
                this.closeToast();
            }, this.duration);
        },
        computed: {
            setToastStatus() {
                return this.type?.toLowerCase() == 'error' ? 'alert' : 'status';
            },

            setToastAriaLive() {
                return this.type?.toLowerCase() == 'error' ? 'assertive' : 'polite';
            },

            setToastType() {
                switch (this.type) {
                    case ToastType.Error:
                        return 'toast-error';
                    case ToastType.Info:
                        return 'toast-info';
                    case ToastType.Success:
                        return 'toast-success';
                    case ToastType.Warning:
                        return 'toast-warning';
                    default:
                        return null;
                }
            },

            setToastIconClass() {
                let currentType = this.type?.toLowerCase();

                switch (currentType) {
                    case ToastType.Error:
                        return 'ri-alert-fill';
                    case ToastType.Info:
                        return 'ri-information-fill';
                    case ToastType.Success:
                        return 'ri-checkbox-circle-fill';
                    case ToastType.Warning:
                        return 'ri-error-warning-fill';
                    default:
                        return this.iconClass;
                }
            }
        },
        methods: {
            closeToast() {
                if (this.timeout) clearTimeout(this.timeout);
                const currentToast: any = this.$refs.toastItem;

                currentToast.classList.remove('bounce-in-right');
                currentToast.classList.remove('opacity-100');

                setTimeout(() => {
                    this.store.clearToast(this.id);
                }, 500);
            }
        }
    });
