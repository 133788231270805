import {
    ICustomComponent,
    ILink,
    IPage,
} from "@/builder/interfaces";
import { ComponentType } from "@/builder/enums";
import { EMPTY_STYLES, generateUID } from "@/builder/utilities";

export const getFooterComponent = (page: IPage | null) => ({
    id: generateUID(),
    parentId: null,
    type: ComponentType.Footer,
    controls: {
        canMove: false,
        canEdit: true,
        canDelete: true,
        allowChildren: false,
        allowMultiple: false
    },
    components: [] as ICustomComponent[],
    styles: {
        ...EMPTY_STYLES,
    },
    customData: {
        logoUrl: page?.pageDesign?.logoUrl,
        logoWidth: 100,
        websiteUrl: page?.pageDesign?.websiteUrl,
        email: "test@example.com",
        phone: "1235551234",
        links: [],
    },
    errors: [],
    label: "Footer",
});

export interface IFooterComponent extends ICustomComponent {
    controls: {
        canMove: false;
        canEdit: true;
        canDelete: true;
        allowChildren: false;
        allowMultiple: false;
    };
    customData: {
        logoWidth: number;
        email: string | null;
        phone: string | null;
        links: ILink[];
    };
}
