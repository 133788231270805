
    import { defineComponent } from 'vue';
    import { useGlobalStore } from '@/stores/globalStore';
    import { AppUrls } from '@/constants/AppUrls';
    import ToastContainer from '@/components/shared/toast/ToastContainer.vue';

    export default defineComponent({
        name: 'App',
        components: {
            'toast-container': ToastContainer as any,
        },
        setup() {
            return { globalStore: useGlobalStore()};
        },
        async beforeMount() {
            if (this.globalStore.checkAuthentication()) {
                await this.globalStore.loadPermissions();
            }
        },
        watch: {
            'globalStore.isAuthenticated': async function (val) {
                if (val) {
                    await this.globalStore.loadPermissions();
                }
                else {
                    //clear out store
                    this.globalStore.setUserContext(null);
                    this.globalStore.setCurrentOrganization(null);
                    this.globalStore.setPermissions([]);

                    //send all open tabs to login
                    this.$router.push(AppUrls.Account.Login);
                }
            }
        }
    });
