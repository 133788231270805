export enum Role {
    Org_SuperAdmin = 4,
    Org_Admin = 3,
    PagesAndPremiums = 30,
    GiftsDonorsExport = 20,
    Projects = 40,
    VirtualTerminal = 50,
    AccountSettings = 60,
    Financial = 70,
    System_Admin = 100,
    System_SuperAdmin = 200,
    System_PlanMgmg = 150
}