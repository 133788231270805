const ApiBaseUrl = process.env.VUE_APP_ENVIRONMENT === "local" ? "/api" : process.env.VUE_APP_API_URL;

export const ApiUrls = {
    ApiBase: ApiBaseUrl,

    Account: {
        Login: `${ApiBaseUrl}/account/login`,
        Logout: `${ApiBaseUrl}/account/logout`,
        ForgotPassword: `${ApiBaseUrl}/account/forgot-password`,
        ResetPassword: `${ApiBaseUrl}/account/reset-password`,
        ChangePassword: `${ApiBaseUrl}/account/change-password`,
        UpdateEmail: `${ApiBaseUrl}/account/update-email`,
        UpdatePhone: `${ApiBaseUrl}/account/update-phone`,
        CurrentOrganization: `${ApiBaseUrl}/account/current-organization`,
        Organizations: `${ApiBaseUrl}/account/organizations`,
        SwitchOrganization: `${ApiBaseUrl}/account/switch-organization`,
        Permissions: `${ApiBaseUrl}/account/permissions`,
        UserData: `${ApiBaseUrl}/account/self`,
        ExchangeToken: `${ApiBaseUrl}/account/exchange-token`,
        CodeProviderList: `${ApiBaseUrl}/account/code-providers`,
        SendCode: `${ApiBaseUrl}/account/send-code`,
        SendEmailCode: `${ApiBaseUrl}/account/send-email-code`,
        SendPhoneCode: `${ApiBaseUrl}/account/send-phone-code`,
        SsoCallback: `${ApiBaseUrl}/account/sso-callback`,
        VerifyCode: `${ApiBaseUrl}/account/verify-code`,
        CreateTwoFactor: `${ApiBaseUrl}/account/create-two-factor`,
        VerifyTwoFactor: `${ApiBaseUrl}/account/verify-two-factor`,
        VerifyEmail: `${ApiBaseUrl}/account/verify-email`,
        SendInvite: (email: string) => `${ApiBaseUrl}/account/send-invite/${email}`,
        FirstLogin: `${ApiBaseUrl}/account/first-login`,
    },

    Images: {
        UploadImage: `${ApiBaseUrl}/image`,
        UpdateImage: (imageId: string) => `${ApiBaseUrl}/image/${imageId}`,
        DeleteImage: (imageId: string) => `${ApiBaseUrl}/image/${imageId}`,
        GetAll: `${ApiBaseUrl}/image`,
        SearchPexels: (filter: string) => `${ApiBaseUrl}/image/pexels?filter=${filter}`
    },

    Campaigns: {
        Get: (campaignId: Number) => `${ApiBaseUrl}/campaign/${campaignId}`,
        Post: `${ApiBaseUrl}/campaign`,
        Put: (campaignId: Number) => `${ApiBaseUrl}/campaign/${campaignId}`,
        Delete: (campaignId: Number) => `${ApiBaseUrl}/campaign/${campaignId}`,
        List: `${ApiBaseUrl}/campaign/list`,
        Query: `${ApiBaseUrl}/campaign/query`,
        GetCampaignDependencyCount: (campaignId: Number) => `${ApiBaseUrl}/campaign/${campaignId}/dependency-count`,
        ReplaceCampaign: `${ApiBaseUrl}/campaign/replace`,
        ToggleSync: (campaignId: Number) => `${ApiBaseUrl}/campaign/${campaignId}/toggle-sync`,
    },

    Donors: {
        Get: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}`,
        Post: `${ApiBaseUrl}/donor`,
        Put: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}`,
        Delete: (donorId: Number | null) => `${ApiBaseUrl}/donor/${donorId}`,
        List: `${ApiBaseUrl}/donor/list`,
        Query: `${ApiBaseUrl}/donor/query`,
        GetImpersonationLink: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/impersonate`,
        UpdateNotes: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/notes`,
        GetDonorIds: `${ApiBaseUrl}/donor/ids/`,
        GetMergeDonor: (donorId: Number) => `${ApiBaseUrl}/donor/merge/${donorId}`,
        MergeDonors: `${ApiBaseUrl}/donor/merge`,

        // Donor Addresses
        GetAddress: (donorId: Number, addressId: Number) => `${ApiBaseUrl}/donor/${donorId}/address/${addressId}`,
        PostAddress: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/address`,
        PutAddress: (donorId: Number, addressId: Number | null) =>
            `${ApiBaseUrl}/donor/${donorId}/address/${addressId}`,
        DeleteAddress: (donorId: Number, addressId: Number | null) =>
            `${ApiBaseUrl}/donor/${donorId}/address/${addressId}`,
        ListAddresses: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/address/list`,

        // Donor Contact Methods
        GetContactMethod: (donorId: Number, contactMethodId: Number) =>
            `${ApiBaseUrl}/donor/${donorId}/contact-method/${contactMethodId}`,
        PostContactMethod: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/contact-method`,
        PutContactMethod: (donorId: Number, contactMethodId: Number) =>
            `${ApiBaseUrl}/donor/${donorId}/contact-method/${contactMethodId}`,
        SetPrimaryContactMethod: (donorId: Number, contactMethodId: Number) =>
            `${ApiBaseUrl}/donor/${donorId}/contact-method/${contactMethodId}/set-primary`,
        DeleteContactMethod: (donorId: Number, contactMethodId: Number | null) =>
            `${ApiBaseUrl}/donor/${donorId}/contact-method/${contactMethodId}`,
        ListContactMethods: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/contact-method/list`,
        EmailAddresses: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/contact-method/email-addresses`,
        PhoneNumbers: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/contact-method/phone-numbers`,

        // Gifts
        GiftsList: (donorId: number) => `${ApiBaseUrl}/donor/${donorId}/gifts`,
        RecurringGiftsList: (donorId: number) => `${ApiBaseUrl}/donor/${donorId}/recurringgifts`,

        // Projects
        GetProjects: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/projects`,
        GetMotivationStatistics: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/motivation-statistics`,
        GetSegmentStatistics: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/segment-statistics`,

        // Activities
        ActivitiesList: (donorId: number) => `${ApiBaseUrl}/donor/${donorId}/activities`,
        ResendDonorPortal: (donorId: Number) => `${ApiBaseUrl}/donor/${donorId}/portal`,
        TransferDonation: `${ApiBaseUrl}/donor/transfer-donation`,
        TransferRecurringSchedule: `${ApiBaseUrl}/donor/transfer-recurring-schedule`,
    },

    GlobalSearch: `${ApiBaseUrl}/search`,

    Exports: {
        List: `${ApiBaseUrl}/export/list`,
        ExportQuery: `${ApiBaseUrl}/export/query`,
        ExportQueryText: `${ApiBaseUrl}/export/query-text`,
        Total: `${ApiBaseUrl}/export/total`,
        Download: (exportId: string) => `${ApiBaseUrl}/export/download/${exportId}`,
        ScheduledExportList: `${ApiBaseUrl}/export/scheduled-export/list`,
        GetScheduledExport: (exportId: number) => `${ApiBaseUrl}/export/scheduled-export/${exportId}`,
        PostScheduledExport: `${ApiBaseUrl}/export/scheduled-export`,
        PutScheduledExport: (exportId: number) => `${ApiBaseUrl}/export/scheduled-export/${exportId}`,
        DeleteScheduledExport: (exportId: number) => `${ApiBaseUrl}/export/scheduled-export/${exportId}`,
        SendScheduledExport: (exportId: number) => `${ApiBaseUrl}/export/scheduled-export/${exportId}/send`,
        ScheduledExportSettings: `${ApiBaseUrl}/export/scheduled-export/settings`,
    },

    Lookups: {
        Countries: `${ApiBaseUrl}/lookup/countries`,
        States: (countryCode: string | null) => `${ApiBaseUrl}/lookup/states/${countryCode}`,
        Currencies: `${ApiBaseUrl}/lookup/currencies`,
        TimeZones: `${ApiBaseUrl}/lookup/timezones`,
        DateFormats: `${ApiBaseUrl}/lookup/dateformats`,
        TimeFormats: `${ApiBaseUrl}/lookup/timeformats`,
        Costs: (organizationId: Number) => `${ApiBaseUrl}/lookup/calculate-costs/${organizationId}`,
        QRCode: (url: string | null) => `${ApiBaseUrl}/lookup/qr-code/?url=${url}`,
    },

    Organizations: {
        Current: `${ApiBaseUrl}/organization/current`,
        Get: `${ApiBaseUrl}/organization`,
        Put: `${ApiBaseUrl}/organization`,
        GetGateways: `${ApiBaseUrl}/organization/gateways`,
        GetDesign: `${ApiBaseUrl}/organization/design`,
        PutDesign: `${ApiBaseUrl}/organization/design`,
        GetOrganizationUserSettings: `${ApiBaseUrl}/organization/user-settings`,
        UpdateUserAutoLogoutSetting: `${ApiBaseUrl}/organization/user-autologout`,
        UpdateRequireTwoFactorSetting: `${ApiBaseUrl}/organization/require-two-factor`,
        DonorPortalSettings: {
            Toggle: `${ApiBaseUrl}/organization/donor-portal-toggle`,
            Get: `${ApiBaseUrl}/organization/donor-portal-settings`,
            Put: `${ApiBaseUrl}/organization/donor-portal-settings`,
        },
    },

    Pages: {
        Get: (id: Number) => `${ApiBaseUrl}/page/${id}`,
        //Exists: (name: string, slug: string | null, pageId: number | null) => `${ApiBaseUrl}/page/exists/?name=${name}&slug=${slug}&pageId=${pageId}`,
        Exists: `${ApiBaseUrl}/page/exists/`,
        Post: `${ApiBaseUrl}/page`,
        Put: (id: Number) => `${ApiBaseUrl}/page/${id}`,
        Copy: (id: Number) => `${ApiBaseUrl}/page/${id}/copy`,
        Delete: (id: Number) => `${ApiBaseUrl}/page/${id}`,
        List: `${ApiBaseUrl}/page/list`,

        //versions
        Version: (id, versionId) => `${ApiBaseUrl}/page/${id}/versions/${versionId}`,
        Versions: (id: Number) => `${ApiBaseUrl}/page/${id}/versions`,
        SwapVersions: (id: Number, versionId: Number) => `${ApiBaseUrl}/page/${id}/versions/${versionId}/swap`,
        Domain: `${ApiBaseUrl}/page/domain`,
        SendTestEmail: `${ApiBaseUrl}/page/test-email`,
    },

    Premiums: {
        Get: (id: Number) => `${ApiBaseUrl}/premium/${id}`,
        Post: `${ApiBaseUrl}/premium`,
        Put: (id: Number) => `${ApiBaseUrl}/premium/${id}`,
        Delete: (id: Number) => `${ApiBaseUrl}/premium/${id}`,
        List: `${ApiBaseUrl}/premium/list`,
        Deactivate: (id: Number) => `${ApiBaseUrl}/premium/${id}/deactivate`,
        Search: (filter: string | null) => `${ApiBaseUrl}/premium/search?filter=${filter}`,
    },

    Projects: {
        Get: (projectId: Number) => `${ApiBaseUrl}/project/${projectId}`,
        Post: `${ApiBaseUrl}/project`,
        Put: (projectId: Number) => `${ApiBaseUrl}/project/${projectId}`,
        Delete: (projectId: Number) => `${ApiBaseUrl}/project/${projectId}`,
        List: `${ApiBaseUrl}/project/list`,
        GetProjectDonors: (projectId: Number) => `${ApiBaseUrl}/project/${projectId}/donors`,
        GetProjectGifts: (projectId: Number) => `${ApiBaseUrl}/project/${projectId}/gifts`,
        Search: (filter: string | null) => `${ApiBaseUrl}/project/search?filter=${filter}`,
        Query: `${ApiBaseUrl}/project/query`,
        GetProjectDependencyCount: (projectId: Number) => `${ApiBaseUrl}/project/${projectId}/dependency-count`,
        SearchProjectsByName: `${ApiBaseUrl}/project/search-by-name`,
        ReplaceProject: `${ApiBaseUrl}/project/replace`
    },

    Queries: {
        Post: `${ApiBaseUrl}/query`,
        Put: (queryId: Number) => `${ApiBaseUrl}/query/${queryId}`,
        Delete: (queryId: Number) => `${ApiBaseUrl}/query/${queryId}`,
        List: (queryType: Number) => `${ApiBaseUrl}/query/list/${queryType}`,
        Lookup: `${ApiBaseUrl}/query/lookup`,
        Parse: `${ApiBaseUrl}/query/parse`,
        Options: (queryType: Number) => `${ApiBaseUrl}/query/options/${queryType}`,
    },

    Segments: {
        Get: (segmentId: Number) => `${ApiBaseUrl}/segment/${segmentId}`,
        Exists: `${ApiBaseUrl}/segment/exists`,
        GetSegmentDonors: (segmentId: Number) => `${ApiBaseUrl}/segment/${segmentId}/donors`,
        GetSegmentGifts: (segmentId: Number) => `${ApiBaseUrl}/segment/${segmentId}/gifts`,
        Post: `${ApiBaseUrl}/segment`,
        Put: (segmentId: Number) => `${ApiBaseUrl}/segment/${segmentId}`,
        Delete: (segmentId: Number) => `${ApiBaseUrl}/segment/${segmentId}`,
        List: (campaignId: Number | null) => `${ApiBaseUrl}/segment/list/${campaignId || ""}`,
        Search: (filter: string | null) => `${ApiBaseUrl}/segment/search?filter=${filter}`,
        Query: `${ApiBaseUrl}/segment/query`,
    },

    Gifts: {
        List: `${ApiBaseUrl}/gift/list`,
        Query: `${ApiBaseUrl}/gift/query`,
        Get: (id: Number) => `${ApiBaseUrl}/gift/${id}`,
        Delete: (id: Number) => `${ApiBaseUrl}/gift/${id}`,
        Refund: (id: Number) => `${ApiBaseUrl}/gift/${id}/refund`,
        UpdateNotes: (giftId: Number) => `${ApiBaseUrl}/gift/${giftId}/notes`,
        ResendGiftEmailAsync: `${ApiBaseUrl}/gift/resend-gift-email`,
    },

    Settings: {
        ApiKeys: {
            Get: (apiKeyId: Number) => `${ApiBaseUrl}/apikey/${apiKeyId}`,
            Post: `${ApiBaseUrl}/apikey`,
            Put: `${ApiBaseUrl}/apikey`,
            Delete: (apiKeyId: Number | null) => `${ApiBaseUrl}/apikey/${apiKeyId}`,
            List: `${ApiBaseUrl}/apiKey/list`,
            Value: (apiKeyId: Number) => `${ApiBaseUrl}/apikey/${apiKeyId}/value`,
        },
        Gateways: {
            List: `${ApiBaseUrl}/gateway/list`
        },
        Integrations: {
            List: `${ApiBaseUrl}/integration/list`,
            Public: `${ApiBaseUrl}/integration/public`,
            Virtuous: {
                Get: `${ApiBaseUrl}/integration/virtuous`,
                Put: `${ApiBaseUrl}/integration/virtuous`,
                Connect: `${ApiBaseUrl}/integration/virtuous-connect`,
                CallBack: `${ApiBaseUrl}/integration/virtuous-callback`
            },
            DoubleTheDoonation: {
                Get: `${ApiBaseUrl}/integration/double-the-donation`,
                Put: `${ApiBaseUrl}/integration/double-the-donation`,
                ValidateKeys: `${ApiBaseUrl}/integration/double-the-donation/validate-keys`,
            },
            GoogleAnalytics: {
                Get: `${ApiBaseUrl}/integration/google-analytics`,
                Put: `${ApiBaseUrl}/integration/google-analytics`,
            }
        },
        Sso: {
            Get: `${ApiBaseUrl}/organization/sso`,
            Post: `${ApiBaseUrl}/organization/sso`,
            SsoPortal: `${ApiBaseUrl}/organization/sso-portal`,
            DirectorySyncPortal: `${ApiBaseUrl}/organization/directory-sync-portal`,
        },
        Users: {
            List: `${ApiBaseUrl}/user/list`,
            Get: (userId: Number) => `${ApiBaseUrl}/user/${userId}`,
            GetOwnAccount: `${ApiBaseUrl}/user/own-account`,
            Post: `${ApiBaseUrl}/user`,
            Put: (userId: Number) => `${ApiBaseUrl}/user/${userId}`,
            Delete: (userId: Number) => `${ApiBaseUrl}/user/${userId}`,
        },
        Webhooks: {
            Get: (webhookId: Number) => `${ApiBaseUrl}/webhook/${webhookId}`,
            EventTypes: `${ApiBaseUrl}/webhook/event-types`,
            Post: `${ApiBaseUrl}/webhook`,
            Put: (webhookId: Number) => `${ApiBaseUrl}/webhook/${webhookId}`,
            Delete: (webhookId: Number) => `${ApiBaseUrl}/webhook/${webhookId}`,
            List: `${ApiBaseUrl}/webhook/list`,
            GetLog: (webhookId: Number, webhookLogId: Number) => `${ApiBaseUrl}/webhook/${webhookId}/log/${webhookLogId}`,
            ListLogs: `${ApiBaseUrl}/webhook/log/list`,
            ListWebhookLogs: (webhookId: Number) => `${ApiBaseUrl}/webhook/${webhookId}/log/list`,
        },
        WhiteLabel: {
            Get: `${ApiBaseUrl}/organization/white-label`,
            Post: `${ApiBaseUrl}/organization/white-label`,
            Token: `${ApiBaseUrl}/organization/white-label/token`,
        },
        Notifications: {
            Get: `${ApiBaseUrl}/emailnotification/list`,
            Put: `${ApiBaseUrl}/emailnotification`
        }
    },

    RecurringGifts: {
        List: `${ApiBaseUrl}/recurringgift/list`,
        Query: `${ApiBaseUrl}/recurringgift/query`,
        Get: (id: number) => `${ApiBaseUrl}/recurringgift/${id}`,
        GiftsList: (id: number) => `${ApiBaseUrl}/recurringgift/${id}/gifts`,
        Put: (id: Number) => `${ApiBaseUrl}/recurringgift/${id}`,
        Cancel: (id: number) => `${ApiBaseUrl}/recurringgift/${id}/cancel`,
        ChargeOneTime: (id: number) => `${ApiBaseUrl}/recurringgift/${id}/onetime`,
    },

    Widgets: {
        WidgetData: (type: number, timeFrame: string) => `${ApiBaseUrl}/widget/widgetdata?timeFrame=${timeFrame}&widgetType=${type}`,
    },

    Activity: {
        List: `${ApiBaseUrl}/activity/list`,
    },

    EmailTemplate: {
        List: `${ApiBaseUrl}/emailtemplate/list`,
        Get: (id: Number) => `${ApiBaseUrl}/emailtemplate/${id}`,
        Post: `${ApiBaseUrl}/emailtemplate/`,
        Put: (id: Number) => `${ApiBaseUrl}/emailtemplate/${id}`,
        Duplicate: (id: Number) => `${ApiBaseUrl}/emailtemplate/${id}/duplicate`,
        Delete: (id: number) => `${ApiBaseUrl}/emailtemplate/${id}/delete`,
    },

    EmailSetting: {
        Url: `${ApiBaseUrl}/emailsetting/`,
        EditorToken: `${ApiBaseUrl}/emailsetting/token`,
        EditorMergeTags: (type: number) => `${ApiBaseUrl}/emailsetting/mergetags?emailTemplateType=${type}`
    },
};
