import { EmailDesign } from "@/stores/emailBuilderStore";
import { DefaultDivider } from "./DefaultDivider";
import { DefaultLogo } from "./DefaultLogo";
import { DefaultTemplate } from "./DefaultTemplate";
import { DefaultText } from "./DefaultText";

export const DefaultAcknowledgementEmail = (design: EmailDesign) => {
    return DefaultTemplate(design, [
        DefaultLogo(design),
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px; font-family: inherit;" data-mce-style="font-size: 14px; line-height: 16px; font-family: inherit;"><p style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;" data-mce-style="font-size: 14px; line-height: 16px; word-break: break-word; text-align: center;"><span style="background-color: transparent; line-height: 14px;" data-mce-style="background-color: transparent; line-height: 14px;"><strong>A gift was made to honor {{TributeFirstName}} {{TributeLastName}}<br></strong></span>{{DonorFirstName}} {{DonorLastName}} made a generous donation to our organization in {{TributeFirstName}}\'s name<span style="background-color: transparent; line-height: 14px;" data-mce-style="background-color: transparent; line-height: 14px;">.</span></p></div>'
        ),
        DefaultText(
            design,
            '<div class="txtTinyMce-wrapper" style="font-size:14px;line-height:16px;font-family:inherit;" data-mce-style="font-size:14px;line-height:16px;font-family:inherit;"><p data-renderer-start-pos="13241" style="line-height:14px;word-break:break-word;" data-mce-style="line-height:14px;word-break:break-word;"><strong data-renderer-mark="true">Organization Name</strong></p><p data-renderer-start-pos="13260" style="line-height:14px;word-break:break-word;" data-mce-style="line-height:14px;word-break:break-word;">{{OrganizationName}}</p><p data-renderer-start-pos="13260" style="line-height:14px;word-break:break-word;" data-mce-style="line-height:14px;word-break:break-word;">&nbsp;</p><p data-renderer-start-pos="13282" style="line-height:14px;word-break:break-word;" data-mce-style="line-height:14px;word-break:break-word;"><strong data-renderer-mark="true">Gift Date </strong></p><p data-renderer-start-pos="13294" style="line-height:14px;word-break:break-word;" data-mce-style="line-height:14px;word-break:break-word;">{{GiftDate}}</p><p data-renderer-start-pos="13294" style="line-height:14px;word-break:break-word;" data-mce-style="line-height:14px;word-break:break-word;">&nbsp;</p><p data-renderer-start-pos="13308" style="line-height:14px;word-break:break-word;" data-mce-style="line-height:14px;word-break:break-word;"><strong data-renderer-mark="true">{{DonorFirstName}}&#39;s Message</strong></p><p data-renderer-start-pos="13338" style="line-height:14px;word-break:break-word;" data-mce-style="line-height:14px;word-break:break-word;">{{AcknowledgementMessage}}</p></div>'
        ),
        DefaultDivider,
    ]);
};
