export enum MergeTag {
    Donor_FirstName = "Donor.FirstName",
    Donor_LastName = "Donor.LastName",
    Donor_Address = "Donor.Address",
    Donor_Email = "Donor.Email",

    Transaction_ID = "Transaction.ID",
    Transaction_TotalAmount = "Transaction.TotalAmount",
    Transaction_Premium = "Transaction.Premium",
    Transaction_ProjectSplit = "Transaction.ProjectSplit",
    Transaction_Frequency = "Transaction.Frequency",

    DonorPortal_Login = "DonorPortal.Login",
}